<template>
  <section class="position-relative gradient-bg ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 col-lg-5 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
          <div class="testimonial-heading text-white">
            <h2 class="text-white">Qué opinan nuestros clientes acerca de <strong>BeCupon</strong></h2>
            <p>
              Sus opiniones son muy importantes para nosotros, nos ayudan a crecer y a mejorar cada día. Muchas gracias de parte de todo nuestro equipo 🫶🏻 🙌🏻.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="testimonial-content-wrap">
            <!-- <div
              class="owl-carousel owl-theme client-testimonial-1 dot-indicator testimonial-shape"
            > -->
            <carousel
              :autoplay="true"
              :dots="true"
              class="owl-theme client-testimonial-1 dot-indicator testimonial-shape owl-loaded owl-drag"
              :loop="true"
              :margin="30"
              :nav="false"
              :responsiveClass="true"
              :autoplayHoverPause="true"
              :lazyLoad="true"
              :items="1"
            >
              <div class="item">
                <div class="testimonial-quote-wrap">
                  <div class="media author-info mb-3">
                    <div class="author-img mr-3">
                      <img
                        src="../../assets/img/client/1.jpg"
                        alt="cliente-1"
                        class="img-fluid"
                      />
                    </div>
                    <div class="media-body text-white">
                      <h5 class="mb-0 color-secondary">Roberto Martínez</h5>
                      <span class="color-secondary">Cliente</span>
                    </div>
                    <i class="fas fa-quote-right color-secondary"></i>
                  </div>
                  <div class="client-say text-gray">
                    <p>
                      Estos cupones son los mejores, me ahorro siempre muchísimo en mis comidas de la Universidad. Gracias por su grabajo. 
                    </p>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimonial-quote-wrap">
                  <div class="media author-info mb-3">
                    <div class="author-img mr-3">
                      <img
                        src="../../assets/img/client/2.jpg"
                        alt="cliente-2"
                        class="img-fluid"
                      />
                    </div>
                    <div class="media-body text-white">
                      <h5 class="mb-0 color-secondary">Mariela Gutierrez</h5>
                      <span class="color-secondary">Cliente</span>
                    </div>
                    <i class="fas fa-quote-right color-secondary"></i>
                  </div>
                  <div class="client-say text-gray">
                    <p>
                      Me encantaron los descuentos familiares son los mejores y más para los niños los fines de semana.
                    </p>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimonial-quote-wrap">
                  <div class="media author-info mb-3">
                    <div class="author-img mr-3">
                      <img
                        src="../../assets/img/client/3.jpg"
                        alt="cliente-3"
                        class="img-fluid"
                      />
                    </div>
                    <div class="media-body text-white">
                      <h5 class="mb-0 color-secondary">Pedro Jimenez</h5>
                      <span class="color-secondary">Cliente</span>
                    </div>
                    <i class="fas fa-quote-right color-secondary"></i>
                  </div>
                  <div class="client-say text-gray">
                    <p>
                      Amo los <strong>BeCupons</strong> me ayudan a ahorrar muchísimo en mis compras. Y en el mapa puedo ver todos los negocios con cupones cerca de mí. Buenísimo rifan.
                    </p>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimonial-quote-wrap">
                  <div class="media author-info mb-3">
                    <div class="author-img mr-3">
                      <img
                        src="../../assets/img/client/4.jpg"
                        alt="cliente-4"
                        class="img-fluid"
                      />
                    </div>
                    <div class="media-body text-white">
                      <h5 class="mb-0 color-secondary">Fernanda Guerrero</h5>
                      <span class="color-secondary">Cliente</span>
                    </div>
                    <i class="fas fa-quote-right color-secondary"></i>
                  </div>
                  <div class="client-say text-gray">
                    <p>
                      Son lo máximo chicos, en los lugares donde vamos a canjear el <strong>BeCupon</strong> siempre nos tratan de maravilla, los recomiendo mucho.
                    </p>
                  </div>
                </div>
              </div>
            </carousel>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "Testimonial",
  components: { carousel },
};
</script>

<style></style>
