<template>
  <div class="pl-2 pr-2 w-100">
    <div
      style="flex-direction: inherit"
      class="background-color card border-0 single-promo-card p-2 mt-4 shadow"
    >
      <div class="card-body card-content card-location">
        <div style="display: inline-block">
          <div class="color-secondary">
            {{ cupon_locations.name }}
          </div>
          <span>
            {{ cupon_locations.address }}
          </span>
        </div>
        <div
          class="go-to-location cursor-pointer d-flex align-items-center"
          @click="goToLocation"
        >
          Mapa
          <i
            class="fas fa-location-dot icon-size-lg color-primary pl-3"
            title="Ir a la ubicación"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "CuponLocations",
  methods: {
    goToLocation() {
      const formattedAddress = encodeURIComponent(this.cupon_locations.address);
      const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;
      window.open(googleMapsLink, "_blank");
    },
  },
  props: {
    // address_location: {
    //   type: String,
    //   required: true,
    // },
    // location_name: {
    //   type: String,
    //   required: true,
    // },
    // hours: {
    //   type: String,
    //   required: true,
    // },
    // link_google: {
    //   type: String,
    //   required: true,
    // },

    cupon_locations: {
      type: Array(),
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.go-to-location {
  display: inline-flex;
}
.card-location {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.background-color {
  background-color: white;
}
</style>
