<script>
import gql from "graphql-tag";
import moment from "moment";

export default {
  name: "Promo",
  props: {
    showDesc: {
      type: Boolean,
      default: false,
    },
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    couponsList: gql`
      {
        couponsList {
          results {
            id
            title
            subTitle
            images {
              id
              url
            }
            free
            couponList {
              id
            }
            initialDate
            expirationDate
            isActive
            available
            newsLetter
            cities {
              id
              name
            }
            newsLetter
            price
          }
        }
      }
    `,
  },
  data: function () {
    return {
      couponsList: [],
      zonasDevolver: "",
      fecha_ctual: moment(new Date()).format("YYYY-MM-DD"),

      expiration: "",
    };
  },

  created() {
    console.log(this.couponsList.results);
  },

  methods: {
    formattedDateExpire(date) {
      const months = [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ];

      // Dividir la fecha en año, mes y día
      const [year, month, day] = date.split("-");

      // Obtener el mes abreviado del array de meses
      const abbreviatedMonth = months[parseInt(month) - 1];

      // Formatear la fecha en el formato deseado: "d mmm yyyy"
      return `${parseInt(day)} ${abbreviatedMonth} ${year}`;
    },
  },
};
</script>

<template>
  <section
    class="promo-section ptb-100"
    v-bind:class="{ 'gray-light-bg': isGray }"
    id="becupons-disponibles"
  >
    <div class="container">
      <div
        v-if="
          couponsList.results
            ? couponsList.results.length
            : false &&
              couponsList.results.some((item) => item.newsLetter) &&
              couponsList.results.some((item) => item.available) &&
              couponsList.results.some(
                (item) => fecha_ctual <= item.expirationDate
              )
        "
        class="row"
      >
        <h2 class="color-secondary text-center w-100">
          Algunos de nuestros <i><strong>BeCupons</strong></i> disponibles
        </h2>
      </div>
      <div v-else class="row">
        <h2 class="color-secondary text-center w-100">
          En este momento no existen
          <i><strong>BeCupons</strong></i> disponibles
        </h2>
      </div>
      <div class="d-flex row justify-content-center">
        <div
          v-for="coupons in this.couponsList.results"
          :key="coupons.id"
          v-if="
            coupons.available &&
            coupons.newsLetter &&
            fecha_ctual <= coupons.expirationDate
          "
          class="col-md-6 col-lg-4 pl-2 pr-2"
        >
          <router-link
            :to="{ name: 'Compras', params: { id: coupons.id } }"
            target="_blank"
          >
            <div class="card border-0 single-promo-card p-2 mt-4 shadow">
              <div class="card-body">
                <div class="pb-2 flex justify-content-center">
                  <img
                    class="inline-flex logo-becoupons"
                    style="border-radius: 8px"
                    v-bind:title="coupons.title"
                    v-bind:src="coupons.images[0].url"
                    alt="Aguacate"
                  />
                </div>
                <div class="cupons-circle">
                  <span class="big-number">
                    {{ coupons.couponList.length }}
                  </span>
                  <span class="small-number">
                    {{ coupons.couponList.length == "1" ? "Cupón" : "Cupones" }}
                  </span>
                </div>

                <div class="pt-2 pb-3">
                  <h5 class="color-secondary mb-0" v-bind:title="coupons.title">
                    {{ coupons.title }}
                  </h5>
                  <!-- <p class="mb-0 location-title">
                  {{ coupons.cities[0].name }}, ...</p> -->
                  <ul class="d-flex">
                    <li
                      v-for="zona in coupons.cities"
                      :key="zona.id"
                      class="mb-0 location-title"
                    >
                      {{ zona.name }},
                    </li>
                  </ul>
                  <div class="d-flex justify-content-between">
                    <div>
                      <div class="flex">
                        <span
                          class="mt-1 inline-flex fas fa-clock icon-size-sx location-icon"
                        ></span>
                        <p class="pl-2 location-info inline-flex mb-0">
                          Válido hasta el
                          {{ formattedDateExpire(coupons.expirationDate) }}
                        </p>
                      </div>
                      <div class="flex">
                        <div>
                          <p class="pl-2 location-info inline-flex mb-0">
                            "{{ coupons.subTitle }}"
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div v-if="coupons.price">
                        <h5 style="color: #ef025c; margin-bottom: 0">
                          ${{
                            coupons.price
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h5>
                        <small style="font-size: 10px; color: #ef025c"
                          >Precio del BeCupon
                        </small>
                      </div>
                      <div v-else>
                        <h5 style="color: green">GRATIS</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.logo-becoupons {
  height: 300px;
  width: 300px;
}

.cupons-circle {
  width: 100px;
  border-radius: 50%;
  background-color: white;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-weight: bolder;
  color: green;
  border: 1px solid;
  position: absolute;
  top: 4%;
  left: 4%;
}
.small-number {
  display: block;
  margin-top: -64px;
}
.big-number {
  font-size: 55px;
  margin-top: -10px;
  display: block;
}
</style>
