<template>
    <router-link :to="`#${id}_${num_cupon}`"  class="col-md-6 col-lg-4 pl-2 pr-2" v-scroll-to="`#${id}_${num_cupon}`">
        <div class="card border-0 single-promo-card p-2 mt-4 shadow">
            <div class="card-body card-content">
                <img class="w-100 border-radius" :title="name" :alt="name" :src="imageUrl">
            </div>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: 'HeaderCard',
        props: {
            about: {
                type: String,
                required: true
            },
            imageUrl: {
                type: String,
                required: true
            },
            num_cupon: {
                type: String,
                required: true
            },
            id: {
                type: String,
                required: true
            },
    },
    // data: function() {
    //         return {
    //             // home: false,
    //             windowTop: 0,
    //             collapsed: true
    //         }
    //     },
    // mounted() {
    //     // alert(window.location.pathname.includes('/inicio'));
    //     // if (window.location.pathname === '/' || window.location.pathname === '/#' || window.location.pathname.includes('/inicio')) {
    //     //     this.home = true;
    //     // }
    //         window.addEventListener("scroll", this.onScroll)
    //         function goToPage(event) {
    //             event.preventDefault(); // stop the browser from navigating to the destination URL.
    //             if (event.target.getAttribute('href') !== '#' && event.target.getAttribute('href') !== '/#') {
    //                 console.log("Href: ", event.target.getAttribute('href'))
    //                 const hrefUrl = event.target.getAttribute('href');
    //                 window.history.pushState({}, window.document.title, hrefUrl) // Update URL as well as browser history.
    //                 const linkTag = document.createElement('link');
    //                 linkTag.setAttribute('rel', 'canonical');
    //                 linkTag.href = 'https://www.becupon.com' + hrefUrl;
    //                 document.head.appendChild(linkTag);
    //                 }
    //             }

    //             // Enable client-side routing for all links on the page
    //             document.querySelectorAll('a').forEach(link => link.addEventListener('click', goToPage));              
    //     },
    //     beforeDestroy() {
    //         window.removeEventListener("scroll", this.onScroll)
    //     },
    //     methods: {
    //         onScroll: function() {
    //             this.windowTop = window.top.scrollY
    //         },
    //         mobileNavClicked: function() {
    //             this.collapsed = !this.collapsed;
    //         }
    //     },
    //     computed: {
    //         hasAffix: function () {
    //             return this.windowTop > 0;
    //         }
    //     }
    
    }
</script>