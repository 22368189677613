var becupons = {
  becupons: {
    aguacate: {
      beCuponName: "Aguacate",
      subtitle: "Los mejores sandwiches de tijuana",
      becupon_description:
        "Este BeCupon es para todos. Incluye puré de papas entre otras cosas",
      cupons_available: "3",
      days_expires: "10",
      becuponscupons_available: "100",
      date_expire: "10 de Marzo 2023",
      cupons: [
        {
          beCuponName: "Aguacate",
          num_cupon: "1",
          id: "aguacate",
          about: "Aguacate",
          subtitle: "Los mejores sandwiches de tijuana",
          becupon_description:
            "Este BeCupon es para todos. Incluye puré de papas entre otras cosas",
          name: "Juegos para todos",
          cupon_description:
            "Este Cupón es para todo. Incluye puré de papas entre otras cosas. Este Cupón es para todo. Incluye puré de papas entre otras cosas. Este Cupón es para todo. Incluye puré de papas entre otras cosas.Este Cupón es para todo. Incluye puré de papas entre otras cosas.",
          short_description: "Incluye puré de papas entre otras cosas",
          real_value: "$300.00 MXN",
          uses_available: "3",
          days_expires: "15",
          date_expire: "10 de Marzo 2023",
          uses_cant: "0",
          cupon_locations: [
            {
              location_name: "Acuacate hipódromo",
              address_location: "Acuacate hipódromo",
              link_google: "Link",
              hours: "7:00 am - 7:00 pm",
            },
            {
              location_name: "Acuacate Zona Río",
              address_location: "Acuacate Zona Río",
              link_google: "Link",
              hours: "6:00 am - 6:00 pm",
            },
          ],
        },
        {
          beCuponName: "Aguacate",
          num_cupon: "2",
          id: "ohzone",
          about: "OhZone",
          subtitle: "Los mejores sandwiches de tijuana",
          becupon_description:
            "Este BeCupon es para todos. Incluye puré de papas entre otras cosas",
          name: "Cupon 2x1",
          cupon_description:
            "Este Cupón es para todo. Incluye puré de papas entre otras cosas. Este Cupón es para todo. Incluye puré de papas entre otras cosas. Este Cupón es para todo. Incluye puré de papas entre otras cosas.Este Cupón es para todo. Incluye puré de papas entre otras cosas.",
          short_description: "Incluye puré de papas entre otras cosas",
          description: "Incluye puré de papas entre otras cosas",
          real_value: "$230.00 MXN",
          uses_available: "2",
          days_expires: "10",
          date_expire: "20 de Marzo 2023",
          uses_cant: "0",
          cupon_locations: [
            {
              location_name: "OhZone Galerías",
              address_location: "OhZone Galerías",
              link_google: "Link",
              hours: "7:00 am - 7:00 pm",
            },
            {
              location_name: "OhZone Otay",
              address_location: "OhZone Galerías",
              link_google: "Link",
              hours: "6:00 am - 6:00 pm",
            },
          ],
        },
        {
          beCuponName: "Aguacate",
          num_cupon: "3",
          id: "sharkys",
          about: "Sharkys",
          subtitle: "Los mejores sandwiches de tijuana",
          becupon_description:
            "Este BeCupon es para todos. Incluye puré de papas entre otras cosas",
          cupon_description:
            "Este Cupón es para todo. Incluye puré de papas entre otras cosas. Este Cupón es para todo. Incluye puré de papas entre otras cosas. Este Cupón es para todo. Incluye puré de papas entre otras cosas.Este Cupón es para todo. Incluye puré de papas entre otras cosas.",
          name: "Lava tu carro",
          short_description: "Incluye puré de papas entre otras cosas",
          real_value: "$150.00 MXN",
          uses_available: "5",
          days_expires: "20",
          date_expire: "4 de Marzo 2023",
          uses_cant: "0",
          cupon_locations: [
            {
              location_name: "Skarkys Aguacaliente",
              address_location: "Skarkys Aguacaliente",
              link_google: "Link",
              hours: "7:00 am - 7:00 pm",
            },
            {
              location_name: "Sharkys Playas de Tijuana",
              address_location: "Skarkys Aguacaliente",
              link_google: "Link",
              hours: "6:00 am - 6:00 pm",
            },
          ],
        },
      ],
    },
  },
};

export default becupons;
