<template>
  <div>
    <nav-bar />
    <div class="main">
      <cupon-header-details
        :title="this.$route.query.beCuponName"
        :subtitle="this.$route.query.subtitle"
        :companyImage="this.$route.query.companyImage"
        :becupon-id="this.$route.query.becuponId"
      />
      <cupon-info-details
        :name="this.$route.query.name"
        :uses_available="this.$route.query.uses_available"
        :becupon_description="this.$route.query.becupon_description"
        :days_expires="this.$route.query.days_expires"
        :activated_cupons="this.$route.query.activated_cupons"
        :cupon_description="this.$route.query.cupon_description"
        :short_description="this.$route.query.short_description"
        :real_value="this.$route.query.real_value"
        :icon1="this.$route.query.icon1"
        :imagen1="this.$route.query.imagen1"
        :cupon_locations="this.$route.query.cupon_locations"
        :uses_cant="this.$route.query.uses_cant"
      />
      <!-- <buy-button :top="false" /> -->
    </div>

    <site-footer-details :show-subscribe="true" />
    <copyright />
  </div>
</template>
<script>
import NavBar from "../views/commons/NavBar";
import CuponHeaderDetails from "../components/cuponDetails/CuponHeaderDetails";
import CuponInfoDetails from "../components/cuponDetails/CuponInfoDetails.vue";
import SiteFooterDetails from "../components/becuponDetails/SiteFooterDetails";
import Copyright from "../views/commons/Copyright";

export default {
  mounted() {
    // console.log("Listado de ubicaciones: ", this.$route.query.cupon_locations);
  },
  name: "CuponDetails",
  components: {
    NavBar,
    SiteFooterDetails,
    CuponHeaderDetails,
    Copyright,
    CuponInfoDetails,
  },
  data() {
    return {
      crumbs: [
        { link: "#", name: "Inicio", isActive: false },
        { link: "#", name: "Somos BeCupon", isActive: true },
      ],
    };
  },
};
</script>
