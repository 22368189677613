<template>
  <footer
    class="footer-1 gradient-bg ptb-60"
    v-bind:class="{ 'footer-with-newsletter': showSubscribe }"
  >
    <div class="container" v-show="showSubscribe">
      <div class="row newsletter-wrap primary-bg rounded shadow-lg p-5">
        <div class="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">
          <div class="newsletter-content text-white">
            <h3 class="mb-0 text-white">Suscríbete a nuestro newsletter</h3>
            <p class="mb-0">
              Recibe nuestro newsletter con los mejores
              <strong>BeCupon</strong> en tu correo.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-5">
          <form class="newsletter-form position-relative">
            <input
              type="text"
              class="input-newsletter form-control"
              placeholder="correo electrónico"
              name="email"
              required
              autocomplete="off"
            />
            <button type="submit" class="disabled">
              <i class="fas fa-paper-plane"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
    <!--subscribe newsletter end-->

    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
          <router-link to="#" v-scroll-to="'#inicio'" class="navbar-brand mb-2">
            <img
              src="../../assets/img/logo-color.png"
              alt="logo-becupon"
              class="img-fluid"
            />
          </router-link>
          <br />
          <p>
            Síguenos en nuestras redes sociales, para conocer
            <strong>BeCupons</strong> especiales y todo nuestro contenido.
          </p>
          <div
            class="list-inline social-list-default background-color social-hover-2 mt-2"
          >
            <li class="list-inline-item">
              <a
                class="facebook"
                href="https://www.facebook.com/becupon"
                target="_blanck"
              >
                <i class="fab fa-facebook"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                class="youtube"
                href="https://www.youtube.com/becupon"
                target="_blanck"
              >
                <i class="fab fa-youtube"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                class="twitter"
                href="https://www.twitter.com/becupon"
                target="_blanck"
              >
                <i class="fab fa-twitter"></i>
              </a>
            </li>
          </div>

          <div class="img-wrap mt-3">
            <img
              rel="icon"
              src="../../assets/img/medio ambiente/logo10.png"
              alt="Te ayudamos a hacer compras inteligentes y ahorrativas"
              class="medioambiente__img1"
            />
          </div>
        </div>
        <div class="col-md-12 col-lg-8">
          <div class="row mt-0">
            <div
              class="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0"
            >
              <h6 class="text-uppercase">Recursos</h6>
              <ul>
                <li>
                  <router-link to="#becupon" v-scroll-to="'#becupon'"
                    >BeCupon</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/#becupons-disponibles"
                    v-scroll-to="'#becupons-disponibles'"
                  >
                    Cupones disponibles
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/#preguntas-frecuentes"
                    v-scroll-to="'#preguntas-frecuentes'"
                    >FaQ</router-link
                  >
                </li>
                <li>
                  <router-link to="#contacto" v-scroll-to="'#contacto'"
                    >Contacto</router-link
                  >
                </li>
                <li>
                  <router-link to="/terminos_y_condiciones">
                    Términos y condiciones
                  </router-link>
                </li>
                <li>
                  <router-link to="/aviso_de_privacidad">
                    Aviso de privacidad
                  </router-link>
                </li>
              </ul>
            </div>
            <div
              class="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0"
            >
              <h6 class="text-uppercase">Enlaces</h6>
              <ul>
                <li>
                  <router-link
                    to="#caracteristicas-de-becupon"
                    v-scroll-to="'#caracteristicas-de-becupon'"
                    >Beneficios</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="#pantallas-becupon"
                    v-scroll-to="'#pantallas-becupon'"
                    >Pantallazos</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="#proceso-de-uso"
                    v-scroll-to="'#proceso-de-uso'"
                    >Guía de uso</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="#intervalos-de-precios-becupon"
                    v-scroll-to="'#intervalos-de-precios-becupon'"
                    >Precios</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="#negocios-asociados"
                    v-scroll-to="'#negocios-asociados'"
                    >Negocios Asociados</router-link
                  >
                </li>

                <li>
                  <router-link to="/eliminar-cuenta"
                    >Eliminar cuenta</router-link
                  >
                </li>
              </ul>
            </div>
            <div
              class="col-sm-4 col-md-4 col-lg-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0"
            >
              <h6 class="text-uppercase">
                Descarga nuestra app en las tiendas
              </h6>
              <div class="action-btns mt-4">
                <ul class="list-inline" style="display: flex">
                  <li class="list-inline-item">
                    <a
                      :href="appstore"
                      target="_blank"
                      class="container-download-text d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                    >
                      <span class="fab fa-apple icon-size-sm mr-3"></span>
                      <div class="download-text text-left inline-block">
                        <small>Descargar desde</small>
                        <h5 class="mb-0">App Store</h5>
                      </div>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      :href="playstore"
                      target="_blank"
                      class="container-download-text d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                    >
                      <span class="fab fa-google-play icon-size-sm mr-3"></span>
                      <div class="download-text text-left">
                        <small>Descargar desde</small>
                        <h5 class="mb-0">Google Play</h5>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end of container-->
  </footer>
</template>

<script>
const appstore = process.env.VUE_APP_DOWNLOAD_APPSTORE;
const playstore = process.env.VUE_APP_DOWNLOAD_PLAYSTORE;

export default {
  name: "SiteFooter",
  props: {
    showSubscribe: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      appstore: appstore,
      playstore: playstore,
    };
  },
};
</script>
