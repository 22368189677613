<template>
  <div class="container">
    <div class="row pl-2 pr-2">
      <div class="card-content mt-4">
        <h1 class="color-green text-center">Información del Cupón</h1>
      </div>
      <cupon-header-card
        :cupon_description="cupon_description"
        :beCuponName="beCuponName"
        :imageUrl="imagen1"
        :uses_available="uses_available"
        :icon1="icon1"
        :name="name"
        :short_description="short_description"
        :real_value="real_value"
        :uses_cant="uses_cant"
      />
    </div>

    <div class="row">
      <card-cupon-info
        name="Expiración:"
        :cant="formatDate(new Date(days_expires))"
      />
      <card-cupon-info name="Puedes utilizarlo:" :cant="this.usos" />
    </div>
    <div class="row cursor-pointer" @click="goToLocation">
      <div class="card-content mt-4">
        <h1 class="color-secondary text-center">
          Ubicaciones donde canjear este Cupón
        </h1>
        <cupon-locations
          v-for="(location_c, index) in cupon_locations"
          :key="index"
          :location_name="location_c.location_name"
          :address_location="location_c.address_location"
          :link_google="location_c.link_google"
          :hours="location_c.hours"
          :cupon_locations="cupon_locations[index]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardCuponInfo from "../cuponDetails/CardCuponInfo";
import CuponHeaderCard from "../cuponDetails/CuponHeaderCard";
import CuponLocations from "../cuponDetails/CuponLocations";

import img1 from "@../../../public/assets/img/aguacate.png";
import icon2 from "@../../../public/assets/img/logo-ohzone-becupon.png";
import img2 from "@../../../public/assets/img/logo-ohzone.png";
import icon3 from "@../../../public/assets/img/logo-sharkys-becupon.jpeg";
import img3 from "@../../../public/assets/img/logo-sharkys.jpeg";

export default {
  data() {
    return {
      img1: img1,
      img2: img2,
      img3: img3,
      icon2: icon2,
      icon3: icon3,

      usos: null,
    };
  },
  nameComponent: "CuponInfoDetails",
  components: { CardCuponInfo, CuponHeaderCard, CuponLocations },
  methods: {
    goToLocation() {},

    formatDate(date) {
      const months = [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ];

      const day = date.getUTCDate();
      const month = months[date.getUTCMonth()];
      const year = date.getUTCFullYear();

      return `${day}  ${month}  ${year}`;
    },
  },
  mounted() {
    // console.log("cupon_locations:", this.cupon_locations);

    this.usos = `${this.uses_cant} ${this.uses_cant == "1" ? "vez" : "veces"}`;
  },
  props: {
    icon1: {
      type: String,
      required: false,
    },
    imagen1: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    beCuponName: {
      type: String,
      required: false,
    },
    short_description: {
      type: String,
      required: false,
    },
    cupon_description: {
      type: String,
      required: false,
    },
    real_value: {
      type: String,
      required: false,
    },
    date_expire: {
      type: String,
      required: false,
    },
    days_expires: {
      type: String,
      required: false,
    },
    uses_available: {
      type: String,
      required: false,
    },
    activated_cupons: {
      type: String,
      required: false,
    },
    uses_cant: {
      type: String,
      required: false,
    },
    cupon_locations: {
      type: Array(),
      required: false,
    },
  },
};
</script>
<style scoped lang="scss">
.color-green {
  color: green;
}
</style>
