<template>
  <div id="app">
    <router-view />

    <button
      id="my-button"
      style="
        position: fixed;
        bottom: 50px;
        right: 50px;
        background-color: #be1a42;
        padding: 10px 15px;
        border-radius: 15px;
        color: wheat;
      "
      v-if="toTopVisible"
      @click="toTop"
    >
      ↑ Arriba
    </button>
  </div>
</template>

<script>
export default {
  name: "app",

  data() {
    return {
      toTopVisible: false,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    handleScroll() {
      this.toTopVisible = window.scrollY > 300;
    },
  },
};
</script>

<style lang="scss"></style>
