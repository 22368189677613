<template>
  <div :data-value="getItem">
    <v-row v-if="isLoading">
      <p>Uploading your file, please wait...</p>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-row>
    <v-row v-else>
      <img ref="image" :src="getImageUrl" class="col-md-2" />
      <v-file-input
        class="col-md-10"
        v-model="uploadObject"
        @change="uploadFile"
        :label="label"
        :error-messages="img_validation_error"
        truncate-length="125"
        show-size
        style="align-self: flex-end"
      />
    </v-row>
  </div>
</template>

<script>
import S3 from "aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

export default {
  data() {
    return {
      isLoading: false,
      fileExtension: "",
      uploadObject: { name: this.obj[this.fieldName] || "" },
      img_validation_error: [],
    };
  },

  watch: {
    getImageUrl: function (url) {
      let img = new Image();

      img.onload = () => {
        let func = this.setErrorMessage;
        if (img.height > this.height || img.width > this.width) {
          func(
            `La imagen debe ser ${this.height} (alto) x ${this.width} (ancho) pixeles como máximo.`
          );
        }
        this.img_validation_error =
          img.height > this.height || img.width > this.width
            ? [
                `La imagen debe ser ${this.height} (alto) x ${this.width} (ancho) pixeles como máximo.`,
              ]
            : [];
      };
      img.src = url;
    },
  },

  computed: {
    config() {
      return {
        bucketName: "becupon-store-qa",
        dirName: this.directory,
        region: "us-east-1",
        accessKeyId: "AKIAROQ7PYIKHHOUQVSU",
        secretAccessKey: "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",
        s3Url: this.baseURL,
      };
    },
    baseURL() {
      return "https://becupon-store-qa.s3.amazonaws.com/";
    },
    S3Client() {
      return new S3(this.config);
    },
    newFileName() {
      return `${Math.random().toString().slice(2, 8)}-${
        this.uploadObject.name
      }`;
    },
    url() {
      return `${this.baseURL}${this.directory}/${this.newFileName}`;
    },
    getImageUrl() {
      if (this.item.logo) {
        return this.uploadObject.name !== ""
          ? this.fileExtension !== ""
            ? `${this.url}`
            : this.item.logo.url
          : "https://becupon-store-qa.s3.amazonaws.com/common/113122-no-image.png.png";
      }
      if (this.item.qrBarcode) {
        return this.uploadObject.name !== ""
          ? this.fileExtension !== ""
            ? `${this.url}.${this.fileExtension}`
            : this.item.qrBarcode.url
          : "https://becupon-store-qa.s3.amazonaws.com/common/113122-no-image.png.png";
      }
      if (this.item.profile && this.item.id > 0) {
        return this.uploadObject.name !== ""
          ? this.fileExtension !== ""
            ? `${this.url}.${this.fileExtension}`
            : this.item.profile.image.url
          : "https://becupon-store-qa.s3.amazonaws.com/common/113122-no-image.png.png";
      }
      if (this.item.id !== -1 && this.item.profile) {
        return this.uploadObject.name !== ""
          ? this.fileExtension !== ""
            ? `${this.url}.${this.fileExtension}`
            : this.item.profile.image.url
          : "https://becupon-store-qa.s3.amazonaws.com/common/113122-no-image.png.png";
      }
      if (this.item.image) {
        return this.uploadObject.name !== ""
          ? this.fileExtension !== ""
            ? `${this.url}.${this.fileExtension}`
            : this.item.image.url
          : "https://becupon-store-qa.s3.amazonaws.com/common/113122-no-image.png.png";
      }
      return this.uploadObject.name !== ""
        ? this.fileExtension !== ""
          ? `${this.url}.${this.fileExtension}`
          : this.imageUrl
        : "https://becupon-store-qa.s3.amazonaws.com/common/113122-no-image.png.png";
    },
    getItem() {
      if (this.item.logo !== undefined && this.item.logo.url !== null) {
        this.obj[this.fieldName] = this.item.logo.url;
        this.uploadObject = { name: this.item.logo.url || "" };
      }
      if (this.item.image !== undefined && this.item.image !== null) {
        this.obj[this.fieldName] = this.item.image.url;
        this.uploadObject = { name: this.item.image.url || "" };
      }
      if (
        this.item.profile !== undefined &&
        this.item.profile.image !== null &&
        this.item.profile.image !== undefined
      ) {
        this.obj[this.fieldName] = this.item.profile.image.url;
        this.uploadObject = { name: this.item.profile.image.url || "" };
      }
      if (this.item.qrBarcode !== undefined && this.item.qrBarcode !== null) {
        this.obj[this.fieldName] = this.item.qrBarcode.url;
        this.uploadObject = { name: this.item.qrBarcode.url || "" };
      }

      return this.item;
    },
  },
  methods: {
    uploadFile() {
      if (this.uploadObject === undefined) {
        this.obj[this.fieldName] = "";
        this.uploadObject = {};
        this.uploadObject["name"] = "";
        this.setImageUpload("");
        this.setPersistError(false);
      } else {
        let file = this.uploadObject;
        this.setPersistError(false);

        if (Math.round(file.size / 1024) >= 2 * 1024) {
          this.setErrorMessage("La imagen no puede pesar más de 2 MB. ");
        } else if (file.type !== "image/png") {
          alert("Solo se permiten imágenes PNG.");

          this.setErrorMessage("Solo se permiten imágenes PNG.");
        } else {
          this.isLoading = true;
          let name = this.newFileName;

          this.S3Client.uploadFile(file, name).finally(() => {
            this.isLoading = false;
            this.fileExtension = file.type.split("/")[1];

            this.obj[this.fieldName] = `${this.url}`;

            this.setImageUpload(`${this.url}.${this.fileExtension}`);
          });
        }
      }
    },
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    directory: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    setImageUpload: {
      type: Function,
      required: true,
    },
    setErrorMessage: {
      type: Function,
      required: true,
    },
    setPersistError: {
      type: Function,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
