<script src="https://www.google.com/recaptcha/api.js"></script>
<script src="https://www.google.com/recaptcha/api.js?render=6LejtuQmAAAAALNC8iO0NvlZ4HmMJkTGFH1EwJaW"></script>

<script
  src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js"
  integrity="sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r"
  crossorigin="anonymous"
></script>
<script
  src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.min.js"
  integrity="sha384-Rx+T1VzGupg4BHQYs2gCW9It+akI2MM/mndMCy36UVfodzcJcF0GGLxZIzObiEfa"
  crossorigin="anonymous"
></script>
<script>
import gql from "graphql-tag";
import axios from "axios";

const PRUEBA = gql`
  query CouponList($coupons: ID!) {
    couponList(coupons: $coupons) {
      results {
        id
        title
        subTitle
        description
        images {
          id
          url
        }
        smallDescription
        valuedAt
        totalUses
        globalUses
        available
        expirationDate
        initialDate
        amountActives
        coupons {
          id
          title
          subTitle
          description
          initialDate
          expirationDate
          price
          value
          amountActives
          couponAmountActive
          categories {
            id
            name
          }
          cities {
            id
            name
          }
          companies {
            id
            name
          }
          images {
            id
            url
          }
          companyImage
        }
      }
      totalCount
    }
  }
`;

const CREATE_USER_MUTATION = `
  mutation CreateUser($newCustomuser: UserInput!) {
    createUser(newCustomuser: $newCustomuser) {
      ok
      customuser {
        id
      }
    }
  }
`;

const UPDATE_USER = `
mutation UpdateUser($newProfile: ProfileUpdateGenericType!) {
  updateProfile(newProfile: $newProfile) {
    ok
    profile {
      id
      city {
        id
        name
      }
    }
  }
}
`;

const url = process.env.VUE_APP_GRAPHQL_HTTP;

export default {
  name: "BeCuponComprar",

  data() {
    return {
      errors: [],
      product: null,
      unit_amount: null,
      name: null,
      description: null,
      image: null,
      success_url: "https://becupon.portalweb.idooproject.com/#/success",
      couponList: null,
      icon1: null,
      imagen1: null,
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      passwordRepeat: null,
      referido: null,
      cupons_id: null,
      username: null,
      userExist: false,
      userInfo: null,

      iva: null,
      fee: null,
      subtotalIva: null,

      totalPrice: null,

      showVerificationCode: false,
      verifiedCode: false,
      becuponId: null,

      countryList: null,
      regionList: null,
      cityList: null,

      country: null,
      region: null,
      city: null,

      countryErrors: [],
      regionErrors: [],
      cityErrors: [],
      emailErrors: [],
      passwordErrors: [],
      rePasswordErrors: [],

      showStripeButton: false,
      timerCountdown: 30,

      codeOff: null,

      showPassword: false,
      showPasswordRepeat: false,

      token: null,
      emailPassword: false,
      emailPasswordErrors: [],

      isAtLeastOneAvailable: true,

      maximumPurchase: false,
    };
  },
  async mounted() {
    await this.$apollo
      .query({
        query: PRUEBA,
        variables: {
          coupons: this.$route.params.id,
        },
      })
      .then((response) => {
        this.couponList = response.data.couponList;
        this.becuponId = this.$route.params.id;
        // console.log(this.couponList);
        // console.log(this.becuponId);
        this.product = response.data.couponList.results[0].coupons;

        this.isAtLeastOneAvailable = this.couponList.results.some(
          (coupon) => coupon.available
        );
      });

    await axios
      .post(url, {
        query: `
      {
  globalConfigurationList {
    results {
      name
      val
    }
  }
}

        `,
      })
      .then((response) => {
        this.iva = response.data.data.globalConfigurationList.results.filter(
          (item) => item.name === "iva_app"
        )[0].val;

        this.fee = parseInt(
          response.data.data.globalConfigurationList.results.filter(
            (item) => item.name === "fee_app"
          )[0].val
        );

        this.subtotalIva = (this.product.price + this.fee) * this.iva;

        if (this.product.price > 0) {
          this.totalPrice = this.product.price + this.fee + this.subtotalIva;
        } else {
          this.totalPrice = 0;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    await axios
      .post(url, {
        query: `
      {
  countryList {
    results {
      id
      name
    }
  }
}

      `,
      })
      .then((response) => {
        this.countryList = response.data.data.countryList.results;
        // console.log(this.countryList);
      });
  },
  components: {
    NavBar,
    SiteFooterDetails,
    Copyright,
    VerificationCode,
    StripeButton,
  },
  methods: {
    async getToken(email, password) {
      try {
        const response = await axios.post(url, {
          query: `
            mutation getToken($email: String, $password: String!) {
              tokenAuth(email: $email, password: $password) {
                token
                success
                user {
                  id
                  firstName
                }
              }
            }
          `,
          variables: {
            email: email,
            password: password,
          },
        });

        const tokenData = response.data.data.tokenAuth;

        if (tokenData.success) {
          // Token retrieval successful
          await this.maximumPurchaseUser(this.userId, tokenData.token);

          this.token = tokenData.token; // Store the token in the data property
          // console.log("Token obtained:", this.token);
        } else {
          this.emailPasswordErrors.push("Contraseña Incorrecta");
          console.error("Token retrieval failed:", tokenData);
        }
      } catch (error) {
        console.error("Error obtaining token:", error);
      }
    },

    async emailChanged() {
      this.userExist = false;
      this.userInfo = null;
      this.showVerificationCode = false;
      this.emailPassword = false;
      this.token = null;
      this.verifiedCode = false;
      this.maximumPurchase = false;

      await this.$apollo
        .query({
          query: PRUEBA,
          variables: {
            coupons: this.$route.params.id,
          },
        })
        .then((response) => {
          this.couponList = response.data.couponList;
          this.becuponId = this.$route.params.id;
          // console.log(this.couponList);
          // console.log(this.becuponId);
          this.product = response.data.couponList.results[0].coupons;
        });
    },

    async fetchCities() {
      if (this.region) {
        try {
          const response = await axios.post(url, {
            query: `
            query GetCitiesByRegion($regionId: ID) {
              cityList(region: $regionId) {
                results {
                  id
                  name
                }
              }
            }
          `,
            variables: {
              regionId: this.region,
            },
          });

          this.cityList = response.data.data.cityList.results;
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      } else {
        this.cityList = null;
      }
    },

    async fetchRegions() {
      if (this.country) {
        try {
          const response = await axios.post(url, {
            query: `
            query GetRegionsByCountry($countryId: ID) {
              regionList(country: $countryId) {
                results {
                  id
                  name
                }
              }
            }
          `,
            variables: {
              countryId: this.country,
            },
          });

          this.regionList = response.data.data.regionList.results;
        } catch (error) {
          console.error("Error fetching regions:", error);
        }
      } else {
        this.regionList = null;
      }
    },

    onSubmit(token) {
      document.getElementById("demo-form").submit();
    },
    // onClick(e) {
    //   e.preventDefault();
    //   grecaptcha.ready(function () {
    //     grecaptcha
    //       .execute("6LejtuQmAAAAALNC8iO0NvlZ4HmMJkTGFH1EwJaW", {
    //         action: "submit",
    //       })
    //       .then(function (token) {});
    //   });
    // },

    async verifyRecaptcha() {
      try {
        const token = await this.executeRecaptcha();
        if (token) {
          this.onSubmit(token);
        } else {
          console.error("reCAPTCHA verification failed.");
        }
      } catch (error) {
        console.error("Error verifying reCAPTCHA:", error);
      }
    },

    executeRecaptcha() {
      return new Promise((resolve) => {
        grecaptcha.ready(() => {
          grecaptcha
            .execute("6LejtuQmAAAAALNC8iO0NvlZ4HmMJkTGFH1EwJaW", {
              action: "submit",
            })
            .then((token) => {
              resolve(token);
            });
        });
      });
    },

    anterior() {
      this.$router.go(-2);
      this.$router.go(-1);
    },

    startTimer() {
      const timerInterval = setInterval(() => {
        if (this.timerCountdown > 0) {
          this.timerCountdown -= 1;
        } else {
          // Cuando el temporizador llega a cero, reiniciar la validación de datos
          clearInterval(timerInterval);

          this.showStripeButton = false;
          this.emailChanged();
        }
      }, 1000); // Actualizar cada segundo
    },

    async maximumPurchaseUser(userId, token) {
      const headers = {
        Authorization: `JWT ${token}`, // Agrega el token en el encabezado
      };

      const response = await axios.post(
        url,
        {
          query: `
        {
          couponsUserList(userId:${this.userId}){
    results{
      id
      title
      maximumCarPurchaseUser
    }
  }
        }
        `,
        },
        {
          headers,
        }
      );

      const coupons = response.data.data.couponsUserList.results;

      const couponWithId = coupons.find(
        (coupon) => coupon.id == this.becuponId
      );

      if (couponWithId) {
        const maximumCarPurchaseUser = couponWithId.maximumCarPurchaseUser;
        const countOfCouponsWithId = coupons.filter(
          (coupon) => coupon.id == this.becuponId
        ).length;

        if (countOfCouponsWithId >= maximumCarPurchaseUser) {
          this.maximumPurchase = true;
          // console.log(true);
        }
      } else {
        this.timerCountdown = 30;
        this.showStripeButton = true;
        this.startTimer();
      }
    },

    checkForm: async function (e) {
      await this.$apollo
        .query({
          query: PRUEBA,
          variables: {
            coupons: this.$route.params.id,
          },
        })
        .then((response) => {
          this.couponList = response.data.couponList;
          this.becuponId = this.$route.params.id;
          this.product = response.data.couponList.results[0].coupons;
        });

      this.errors = [];
      this.countryErrors = [];
      this.regionErrors = [];
      this.cityErrors = [];
      this.emailErrors = [];
      this.passwordErrors = [];
      this.rePasswordErrors = [];
      this.emailPasswordErrors = [];

      if (!this.email) this.errors.push("Correo requerido.");

      if (!this.userExist && this.email) {
        axios
          .post(url, {
            query: `
          query getUserByEmail($email:String!){
            userByEmail(email:$email){
              id
              username
              email
              firstName
              lastName
              isActive
            }
          }
          `,
            variables: {
              email: this.email,
            },
          })
          .then((response) => {
            this.userInfo = response.data.data.userByEmail;

            // console.log(this.userInfo);

            if (this.userInfo) {
              // console.log("existe");
              this.userId = this.userInfo.id;
              // console.log(this.userId);

              this.emailPassword = true;

              // this.timerCountdown = 30;
              // this.showStripeButton = true;
              // this.startTimer();

              // this.$router.push({ name: "SuccessPage" });
            } else {
              this.userExist = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (this.password == this.passwordRepeat) {
          if (this.email && this.password && this.city) {
            // console.log("ok");

            const newUserInput = {
              password: this.password,
              email: this.email,
              username: this.email,
              firstName: this.first_name,
              lastName: this.last_name,
            };

            try {
              const response = await axios.post(url, {
                query: CREATE_USER_MUTATION,
                variables: { newCustomuser: newUserInput },
              });

              const data = response.data.data.createUser;

              if (data.ok) {
                this.showVerificationCode = true;

                this.userId = data.customuser.id;

                const newProfile = {
                  id: this.userId,
                  country: this.country,
                  region: this.region,
                  city: this.city,
                  foreignRef: this.referido,
                };

                await axios
                  .post(url, {
                    query: UPDATE_USER,
                    variables: { newProfile: newProfile },
                  })
                  .then((response) => {
                    // console.log(response.data.data.updateProfile.ok);
                    // console.log("Perfil actualizado");
                    this.getToken(this.email, this.password);
                    this.emailPassword = true;
                  });
                // .then((response) => {
                //   if (this.token) {
                //     this.timerCountdown = 30;
                //     this.showStripeButton = true;
                //     this.startTimer();
                //   }
                // });
              } else {
                console.error("User creation failed.");
              }
              if (response.data.errors) {
                console.error("GraphQL errors:", response.data.errors);
              }
            } catch (error) {
              console.error("An error occurred while creating user:", error);
            }
          }
        }

        if (this.userExist) {
          this.errors = [];
          if (!this.email) this.emailErrors.push("Correo requerido.");
          if (!this.password) this.passwordErrors.push("Contraseña requerida.");
          if (this.password !== this.passwordRepeat)
            this.rePasswordErrors.push("Las contraseñas no coinciden.");

          if (!this.country) this.countryErrors.push("Seleccione un país.");
          if (!this.region) this.regionErrors.push("Seleccione un estado.");
          if (!this.city) this.cityErrors.push("Seleccione una ciudad.");
        }
      }

      if (this.emailPassword && this.email) {
        await this.getToken(this.email, this.password);
      }

      // e.preventDefault();
    },

    async onVerificationSuccess() {
      this.verifiedCode = true;
      await this.getToken(this.email, this.password);

      this.timerCountdown = 30;
      this.showStripeButton = true;
      this.startTimer();
    },
  },
  computed: {},
  filters: {
    currency(val) {
      return `${val.toFixed(2)} €`;
    },
  },
};
import NavBar from "../views/commons/NavBar";
import SiteFooterDetails from "../components/becuponDetails/SiteFooterDetails";
import Copyright from "../views/commons/Copyright";
import VerificationCode from "../components/VerificationCode";
import StripeButton from "../components/becuponDetails/StripeButton";
</script>

<template>
  <div>
    <nav-bar />
    <body
      class="bg-body-tertiary page-header-section ptb-100 page-header-bg bg-image"
    >
      <div class="container">
        <main>
          <button
            @click="anterior"
            type="button"
            class="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
          >
            Regresar
          </button>

          <div class="py-5 text-center">
            <h2>Datos Necesarios</h2>
            <p class="lead">
              Debe tener presente, que los datos que use en este formulario,
              serán con los que se le generará toda la compra del Becupon. En
              caso de ser requerida esta información en lo adelante, debe
              coincidir con la que ponga acá. Gracias por su atención.
            </p>
          </div>

          <div class="row">
            <div v-if="isAtLeastOneAvailable" class="col-md-6">
              <div v-if="!showVerificationCode">
                <h4 class="mb-3">Datos Personales</h4>
                <form id="app" @submit.prevent="checkForm()" method="post">
                  <div class="row g-3">
                    <div v-if="errors.length">
                      <b> Por favor complete los siguientes campos: </b>
                      <ul>
                        <li v-for="error in errors" style="color: red">
                          {{ error }}
                        </li>
                      </ul>
                    </div>

                    <div class="col-12">
                      <label for="email" class="form-label">Correo *</label>
                      <input
                        v-model="email"
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        @change="emailChanged"
                      />
                      <div v-if="emailErrors.length" class="text-danger">
                        <ul>
                          <li v-for="error in emailErrors">{{ error }}</li>
                        </ul>
                      </div>

                      <div v-if="emailPassword">
                        <div class="col-12">
                          <label for="password" class="form-label"
                            >Contraseña *
                          </label>

                          <div class="d-flex">
                            <input
                              v-model="password"
                              :type="showPassword ? 'text' : 'password'"
                              class="form-control mr-2"
                              id="password"
                              name="password"
                            />
                            <button
                              class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                              @click="showPassword = !showPassword"
                            >
                              <i
                                v-if="showPassword"
                                class="fas fa-eye-slash"
                              ></i>
                              <i v-else class="fas fa-eye"></i>
                            </button>
                          </div>
                        </div>

                        <div
                          v-if="emailPasswordErrors.length"
                          class="text-danger"
                        >
                          <ul>
                            <li v-for="error in emailPasswordErrors">
                              {{ error }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div v-if="userExist" class="col-12">
                      <span style="color: red">
                        Correo no registrado, por favor regístrese.
                      </span>
                      <div class="col-12">
                        <label for="first_name" class="form-label"
                          >Nombre
                        </label>
                        <input
                          v-model="first_name"
                          type="text"
                          class="form-control"
                          id="first_name"
                          name="first_name"
                        />
                      </div>
                      <div class="col-12">
                        <label for="last_name" class="form-label"
                          >Apellidos
                        </label>
                        <input
                          v-model="last_name"
                          type="text"
                          class="form-control"
                          id="last_name"
                          name="last_name"
                        />
                      </div>

                      <div class="col-12">
                        <label for="country" class="form-label"> País </label>
                        <select
                          v-model="country"
                          class="form-select"
                          id="country"
                          name="country"
                          @change="fetchRegions"
                        >
                          <option value="">Seleccione un país</option>
                          <option
                            v-for="countryOption in countryList"
                            :key="countryOption.id"
                            :value="countryOption.id"
                          >
                            {{ countryOption.name }}
                          </option>
                        </select>
                        <div v-if="countryErrors.length" class="text-danger">
                          <ul>
                            <li v-for="error in countryErrors">{{ error }}</li>
                          </ul>
                        </div>
                      </div>

                      <div class="col-12">
                        <label for="region" class="form-label"> Estado </label>
                        <select
                          v-model="region"
                          class="form-select"
                          id="region"
                          name="region"
                          @change="fetchCities"
                        >
                          <option value="">Seleccione un estado</option>
                          <option
                            v-for="regionOption in regionList"
                            :key="regionOption.id"
                            :value="regionOption.id"
                          >
                            {{ regionOption.name }}
                          </option>
                        </select>
                        <div v-if="regionErrors.length" class="text-danger">
                          <ul>
                            <li v-for="error in regionErrors">{{ error }}</li>
                          </ul>
                        </div>
                      </div>

                      <div class="col-12">
                        <label for="city" class="form-label"> Ciudad </label>
                        <select
                          v-model="city"
                          class="form-select"
                          id="city"
                          name="city"
                        >
                          <option value="">Seleccione una ciudad</option>
                          <option
                            v-for="cityOption in cityList"
                            :key="cityOption.id"
                            :value="cityOption.id"
                          >
                            {{ cityOption.name }}
                          </option>
                        </select>

                        <div v-if="cityErrors.length" class="text-danger">
                          <ul>
                            <li v-for="error in cityErrors">{{ error }}</li>
                          </ul>
                        </div>
                      </div>

                      <div class="col-12">
                        <label for="password" class="form-label"
                          >Contraseña *
                        </label>

                        <div class="d-flex">
                          <input
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            class="form-control mr-2"
                            id="password"
                            name="password"
                          />
                          <button
                            class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                            @click="showPassword = !showPassword"
                          >
                            <i v-if="showPassword" class="fas fa-eye-slash"></i>
                            <i v-else class="fas fa-eye"></i>
                          </button>
                        </div>

                        <div v-if="passwordErrors.length" class="text-danger">
                          <ul>
                            <li v-for="error in passwordErrors">{{ error }}</li>
                          </ul>
                        </div>
                      </div>

                      <div class="col-12">
                        <label for="repassword" class="form-label"
                          >Repetir contraseña *
                        </label>

                        <div class="d-flex">
                          <input
                            v-model="passwordRepeat"
                            :type="showPasswordRepeat ? 'text' : 'password'"
                            class="form-control mr-2"
                            id="passwordRepeat"
                            name="passwordRepeat"
                          />

                          <button
                            class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                            @click="showPasswordRepeat = !showPasswordRepeat"
                          >
                            <i
                              v-if="showPasswordRepeat"
                              class="fas fa-eye-slash"
                            ></i>
                            <i v-else class="fas fa-eye"></i>
                          </button>
                        </div>

                        <div v-if="rePasswordErrors.length" class="text-danger">
                          <ul>
                            <li v-for="error in rePasswordErrors">
                              {{ error }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label for="referido" class="form-label"
                          >Código de Referido</label
                        >
                        <input
                          v-model="referido"
                          type="text"
                          class="form-control"
                          id="referido"
                          name="referido"
                          placeholder=""
                          value=""
                        />
                      </div>
                    </div>

                    <div class="container mt-4">
                      <div v-if="maximumPurchase" class="mb-3">
                        <span class="text-danger">
                          Usted ha alcanzado el número máximo de compras de este
                          Becupon.
                        </span>
                      </div>

                      <div
                        v-if="userInfo && !userInfo.isActive"
                        class="text-center"
                      >
                        <span class="text-danger">
                          Esta cuenta fue eliminada o esta pendiente de
                          verificación, intente con otra.
                        </span>
                      </div>
                      <div
                        v-if="
                          token &&
                          !maximumPurchase &&
                          userInfo &&
                          userInfo.isActive &&
                          this.couponList.results[0].amountActives > 5
                        "
                        class="text-center"
                      >
                        <span class="text-success">
                          Correo verificado, proceda a la compra.
                        </span>

                        <div>
                          <h2>
                            {{ this.timerCountdown }}
                          </h2>
                          <span>
                            Realice la compra antes de finalizar el tiempo.
                          </span>
                        </div>

                        <StripeButton
                          :amount="this.totalPrice"
                          :description="this.product.title"
                          :productImage="
                            this.couponList.results[0].coupons.images[0].url
                          "
                          :email="this.email"
                          :cuponId="this.becuponId"
                          :iva="this.iva"
                          :fee="this.fee"
                          :userId="this.userId"
                          :token="this.token"
                        ></StripeButton>
                      </div>

                      <div class="row pl-2 pr-2 justify-content" v-else>
                        <div class="col-md-6 col-lg-6 display-contents">
                          <!-- @click="verifyRecaptcha" -->
                          <input
                            class="g-recaptcha w-100 d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                            style="font-size: 30px"
                            type="submit"
                            value="Verificar datos"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div
                v-if="
                  verifiedCode && this.couponList.results[0].amountActives > 5
                "
              >
                <div class="col-12 mb-5">
                  <label for="email" class="form-label">{{ this.email }}</label>
                </div>

                <div class="text-center">
                  <h2>
                    {{ this.timerCountdown }}
                  </h2>
                  <span> Realice la compra antes de finalizar el tiempo. </span>
                </div>

                <StripeButton
                  :amount="this.totalPrice"
                  :description="this.product.title"
                  :productImage="
                    this.couponList.results[0].coupons.images[0].url
                  "
                  :email="this.email"
                  :cuponId="this.becuponId"
                  :iva="this.iva"
                  :fee="this.fee"
                  :userId="this.userId"
                  :token="this.token"
                ></StripeButton>
              </div>
              <div v-if="!verifiedCode">
                <VerificationCode
                  v-if="showVerificationCode"
                  :user-id="userId"
                  @verification-success="onVerificationSuccess"
                ></VerificationCode>
              </div>
            </div>
            <div v-if="this.product" class="col-md-6">
              <h4 class="mb-3 text-center">
                <span>Becupon:</span>
              </h4>
              <ul class="list-group mb-3">
                <li
                  class="list-group-item d-flex justify-content-between lh-sm"
                >
                  <div>
                    <div
                      class="card border-0 single-promo-card p-2 mt-2 shadow"
                    >
                      <div class="card-content">
                        <img
                          class="w-100 border-radius"
                          v-bind:src="
                            this.couponList.results[0].coupons.images[0].url
                          "
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between lh-sm"
                >
                  <div>
                    <h6 class="my-0">Nombre:</h6>
                  </div>
                  <span class="text-body-secondary">{{
                    this.product.title
                  }}</span>
                </li>
                <li
                  class="list-group-item d-flex flex-row justify-content-between lh-sm"
                >
                  <div>
                    <h6 class="my-0">Subtítulo:</h6>
                  </div>
                  <span class="text-body-secondary">{{
                    this.product.subTitle
                  }}</span>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between lh-sm"
                >
                  <div>
                    <h6 class="my-0">Descripción:</h6>
                    <small class="text-body-secondary">{{
                      this.product.description
                    }}</small>
                  </div>
                </li>
                <li
                  class="list-group-item d-flex flex-column justify-content-between"
                  v-if="this.totalPrice"
                >
                  <div class="d-flex flex-row justify-content-between">
                    <h5 class="text-success">Total:</h5>

                    <h5>
                      <strong class="text-success">
                        ${{
                          this.totalPrice
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                        MXN
                      </strong>
                    </h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </div>
    </body>
    <site-footer-details :show-subscribe="true" />
    <copyright />
  </div>
</template>
<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css";

.input,
select {
  margin-left: 10px;
}
</style>
