<template>
  <div>
    <nav-bar />

    <body
      class="bg-body-tertiary page-header-section ptb-100 page-header-bg bg-image"
    >
      <div class="container d-flex flex-column mt-2" v-if="!affiliationOk">
        <h2 class="color-secondary">Afíliate a Becupon</h2>

        <p>
          Si deseas afiliar tu negocio a Becupon completa el siguiente
          formulario, revisaremos tu información y nos pondremos en contacto:
        </p>

        <!-- titulos -->
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <span
              :class="{
                activee: selectedSection === 'empresa',
                inactive: selectedSection !== 'empresa',
                completed: completedSections.includes('empresa'),
              }"
              >1</span
            >
            <span
              :class="{ 'hide-on-mobile': selectedSection !== 'empresa' }"
              class="pointer p-2 rounded rounded"
            >
              <!-- @click="selectedSection = 'empresa'" -->
              Datos de la empresa</span
            >
          </div>

          <div class="d-flex align-items-center">
            <span
              :class="{
                activee: selectedSection === 'sucursal',
                inactive: selectedSection !== 'sucursal',
                completed: completedSections.includes('sucursal'),
              }"
              >2</span
            >

            <span
              :class="{ 'hide-on-mobile': selectedSection !== 'sucursal' }"
              class="pointer p-2 rounded"
            >
              <!-- @click="selectedSection = 'sucursal'" -->
              Sucursal(es)</span
            >
          </div>

          <div class="d-flex align-items-center">
            <span
              :class="{
                activee: selectedSection === 'paquete',
                inactive: selectedSection !== 'paquete',
                completed: completedSections.includes('paquete'),
              }"
              >3</span
            >

            <span
              :class="{ 'hide-on-mobile': selectedSection !== 'paquete' }"
              class="pointer p-2 rounded"
            >
              <!-- @click="selectedSection = 'paquete'" -->
              Paquete
            </span>
          </div>

          <div class="d-flex align-items-center">
            <span
              :class="{
                activee: selectedSection === 'admin',
                inactive: selectedSection !== 'admin',
                completed: completedSections.includes('admin'),
              }"
              >4</span
            >

            <span
              :class="{ 'hide-on-mobile': selectedSection !== 'admin' }"
              class="pointer p-2 rounded"
            >
              <!-- @click="selectedSection = 'admin'" -->
              Administrador</span
            >
          </div>

          <div class="d-flex align-items-center">
            <span
              :class="{
                activee: selectedSection === 'empleado',
                inactive: selectedSection !== 'empleado',
                completed: completedSections.includes('empleado'),
              }"
              >5</span
            >

            <span
              :class="{ 'hide-on-mobile': selectedSection !== 'empleado' }"
              class="pointer p-2 rounded"
            >
              <!-- @click="selectedSection = 'empleado'" -->
              Empleado(s)
            </span>
          </div>

          <div class="d-flex align-items-center">
            <span
              :class="{
                activee: selectedSection === 'cupon',
                inactive: selectedSection !== 'cupon',
                completed: completedSections.includes('cupon'),
              }"
              >6</span
            >

            <span
              :class="{ 'hide-on-mobile': selectedSection !== 'cupon' }"
              class="pointer p-2 rounded"
            >
              <!-- @click="selectedSection = 'cupon'" -->
              Cupón(es)
            </span>
          </div>
        </div>

        <!-- secciones -->
        <div class="pt-4">
          <div v-if="selectedSection === 'empresa'">
            <form @submit.prevent="submitEmpresaForm">
              <div class="form-group">
                <label for="nombreEmpresa">1. Nombre de la empresa*</label>
                <input
                  type="text"
                  id="nombreEmpresa"
                  class="form-control"
                  v-model="empresa.nombre"
                  maxlength="50"
                  required
                />
              </div>

              <div class="form-group">
                <label for="descripcionEmpresa">2. Descripción*</label>
                <input
                  type="text"
                  id="descripcionEmpresa"
                  class="form-control"
                  v-model="empresa.descripcion"
                  maxlength="100"
                  required
                />
              </div>

              <div class="form-group">
                <label>3. Horario de operación*</label>
                <div class="d-flex align-items-center">
                  <div class="mr-2">
                    <label for="horarioDesde">Desde:</label>
                    <input
                      type="time"
                      id="horarioDesde"
                      class="form-control"
                      v-model="empresa.horarioDesde"
                      required
                    />
                  </div>
                  <div>
                    <label for="horarioHasta">Hasta:</label>
                    <input
                      type="time"
                      id="horarioHasta"
                      class="form-control"
                      v-model="empresa.horarioHasta"
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="telefonoEmpresa">4. Teléfono*</label>
                <input
                  type="tel"
                  id="telefonoEmpresa"
                  class="form-control"
                  v-model="empresa.telefono"
                  maxlength="10"
                  @keypress="allowNumbersOnly"
                  required
                />
              </div>

              <div class="form-group d-flex flex-column">
                <label for="logoEmpresa">5. Logo de la empresa*</label>

                <S3FileUpload
                  :obj="empresa.logo"
                  fieldName="logoUrl"
                  directory="company-logo"
                  label="Logo 600x600 máximo"
                  :set-image-upload="setLogoUpload"
                  :set-error-message="setError"
                  :set-persist-error="setPersistError"
                  :width="600"
                  :height="600"
                  :item="company"
                  :is-logo="false"
                />
                <small class="form-text text-muted"
                  >Formato .PNG, máximo 5MB</small
                >
              </div>
            </form>
          </div>

          <div v-if="selectedSection === 'sucursal'">
            <form @submit.prevent="submitSucursalForm">
              <div v-for="(sucursal, index) in sucursales" :key="index">
                <h3>Sucursal #{{ index + 1 }}</h3>
                <div class="form-group">
                  <label :for="'nombreSucursal-' + index"
                    >1. Nombre de la sucursal*</label
                  >
                  <input
                    :id="'nombreSucursal-' + index"
                    type="text"
                    class="form-control"
                    v-model="sucursal.nombre"
                    maxlength="50"
                    required
                  />
                </div>

                <div class="form-group">
                  <label :for="'domicilioSucursal-' + index"
                    >2. Domicilio*</label
                  >
                  <textarea
                    :id="'domicilioSucursal-' + index"
                    class="form-control"
                    v-model="sucursal.domicilio"
                    maxlength="200"
                    required
                  ></textarea>
                </div>

                <div class="form-group">
                  <label :for="'horarioDesdeSucursal-' + index"
                    >3. Horario de operación*</label
                  >
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      <label :for="'horarioDesdeSucursal-' + index"
                        >Desde:</label
                      >
                      <input
                        :id="'horarioDesdeSucursal-' + index"
                        type="time"
                        class="form-control"
                        v-model="sucursal.horarioDesde"
                        required
                      />
                    </div>
                    <div>
                      <label :for="'horarioHastaSucursal-' + index"
                        >Hasta:</label
                      >
                      <input
                        :id="'horarioHastaSucursal-' + index"
                        type="time"
                        class="form-control"
                        v-model="sucursal.horarioHasta"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label :for="'telefonoSucursal-' + index">4. Teléfono*</label>
                  <input
                    :id="'telefonoSucursal-' + index"
                    type="tel"
                    class="form-control"
                    v-model="sucursal.telefono"
                    maxlength="10"
                    @keypress="allowNumbersOnly"
                    required
                  />
                </div>

                <button
                  v-if="index > 0"
                  class="btn btn-danger btn-sm"
                  @click="removeSucursal(index)"
                >
                  Eliminar Sucursal
                </button>

                <hr />
              </div>

              <button
                class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded float-left"
                @click="addSucursal"
              >
                Agregar Sucursal
              </button>
            </form>
          </div>

          <div v-if="selectedSection === 'paquete'">
            <form @submit.prevent="submitPaqueteForm">
              <div class="form-group">
                <label>Seleccione un paquete*</label>
                <div
                  v-for="paquete in paquetes"
                  :key="paquete.id"
                  class="form-check"
                >
                  <input
                    type="radio"
                    :id="'paquete' + paquete.id"
                    class="form-check-input"
                    :value="paquete.id"
                    v-model="paqueteSeleccionado"
                    required
                  />
                  <label :for="'paquete' + paquete.id" class="form-check-label">
                    <strong>{{ paquete.name }}: </strong>
                  </label>
                  <p v-if="paquete.price > 0">
                    ${{
                      paquete.price
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                    MXN
                  </p>
                  <p>{{ paquete.description }}</p>
                </div>
              </div>
            </form>
          </div>

          <div v-if="selectedSection === 'admin'">
            <form @submit.prevent="submitAdminForm">
              <div class="form-group">
                <label for="nombreAdmin"
                  >1. Nombre de la persona autorizada*</label
                >
                <input
                  type="text"
                  id="nombreAdmin"
                  class="form-control"
                  v-model="admin.nombre"
                  maxlength="50"
                  required
                />
              </div>

              <div class="form-group">
                <label for="apellidoAdmin"
                  >2. Apellidos de la persona autorizada*</label
                >
                <input
                  type="text"
                  id="apellidoAdmin"
                  class="form-control"
                  v-model="admin.apellido"
                  maxlength="50"
                  required
                />
              </div>

              <div class="form-group">
                <label for="correoAdmin">3. Correo electrónico*</label>
                <input
                  type="email"
                  id="correoAdmin"
                  class="form-control"
                  v-model="admin.correo"
                  maxlength="50"
                  required
                />
              </div>

              <div class="form-group">
                <label for="contrasenaAdmin">4. Contraseña*</label>
                <div class="d-flex">
                  <input
                    :type="showAdminPassword ? 'text' : 'password'"
                    id="contrasenaAdmin"
                    class="form-control"
                    v-model="admin.contrasena"
                    maxlength="50"
                    required
                  />
                  <button
                    class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                    @click="showAdminPassword = !showAdminPassword"
                  >
                    <i v-if="showAdminPassword" class="fas fa-eye-slash"></i>
                    <i v-else class="fas fa-eye"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div v-if="selectedSection === 'empleado'">
            <form @submit.prevent="submitEmpleadoForm">
              <div v-for="(empleado, index) in empleados" :key="index">
                <h3>Empleado #{{ index + 1 }}</h3>
                <div class="form-group">
                  <label :for="'nombreEmpleado-' + index"
                    >1. Nombre del empleado*</label
                  >
                  <input
                    :id="'nombreEmpleado-' + index"
                    type="text"
                    class="form-control"
                    v-model="empleado.nombre"
                    maxlength="50"
                    required
                  />
                </div>

                <div class="form-group">
                  <label :for="'apellidosEmpleado-' + index"
                    >2. Apellidos del empleado*</label
                  >
                  <input
                    :id="'apellidosEmpleado-' + index"
                    type="text"
                    class="form-control"
                    v-model="empleado.apellido"
                    maxlength="50"
                    required
                  />
                </div>

                <div class="form-group">
                  <label :for="'correoEmpleado-' + index"
                    >3. Correo electrónico de acceso a la app de
                    sucursales*</label
                  >
                  <input
                    :id="'correoEmpleado-' + index"
                    type="email"
                    class="form-control"
                    v-model="empleado.correo"
                    maxlength="50"
                    required
                  />
                  <small
                    v-if="correoEmpleadoInvalido(index) == 'admin'"
                    class="form-text text-danger"
                  >
                    El correo electrónico no puede coincidir con el del admin.
                  </small>

                  <small
                    v-else-if="correoEmpleadoInvalido(index) == 'otroEmpleado'"
                    class="form-text text-danger"
                  >
                    El correo electrónico no puede coincidir con el de otro
                    empleado.
                  </small>
                </div>

                <div class="form-group">
                  <label :for="'contrasenaEmpleado-' + index"
                    >4. Contraseña de acceso a la app de sucursales*</label
                  >
                  <div class="d-flex">
                    <input
                      :id="'contrasenaEmpleado-' + index"
                      :type="showEmpleadoPassword ? 'text' : 'password'"
                      class="form-control"
                      v-model="empleado.contrasena"
                      maxlength="50"
                      required
                    />

                    <button
                      class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                      @click="showEmpleadoPassword = !showEmpleadoPassword"
                    >
                      <i
                        v-if="showEmpleadoPassword"
                        class="fas fa-eye-slash"
                      ></i>
                      <i v-else class="fas fa-eye"></i>
                    </button>
                  </div>
                </div>

                <div class="form-group">
                  <label :for="'sucursalEmpleado-' + index"
                    >5. Agregar sucursal donde este empleado va a hacer válido
                    los cupones del cliente*</label
                  >
                  <select
                    :id="'sucursalEmpleado-' + index"
                    class="form-control"
                    v-model="empleado.sucursal"
                    required
                  >
                    <option value="">Seleccionar sucursal</option>
                    <option
                      v-for="(sucursal, index) in sucursales"
                      :key="index"
                      :value="sucursal.nombre"
                    >
                      {{ sucursal.nombre }}
                    </option>
                  </select>
                </div>

                <button
                  v-if="index > 0"
                  class="btn btn-danger btn-sm"
                  @click="removeEmpleado(index)"
                >
                  Eliminar Empleado
                </button>

                <hr />
              </div>

              <button
                class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded float-left"
                @click="addEmpleado"
              >
                Agregar Empleado
              </button>
            </form>
          </div>

          <div v-if="selectedSection === 'cupon'">
            <form @submit.prevent="submitCuponForm">
              <div v-for="(cupon, index) in cupones" :key="index">
                <h3>Promoción o cupón #{{ index + 1 }}</h3>
                <div class="form-group">
                  <label for="nombrePromocion"
                    >1. Nombre de la promoción*</label
                  >
                  <input
                    type="text"
                    :id="'nombrePromocion_' + index"
                    class="form-control"
                    v-model="cupon.nombrePromocion"
                    maxlength="20"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="cantidadCupones"
                    >2. Cantidad total de cupones (mínimo 100 cupones)*</label
                  >
                  <input
                    type="number"
                    :id="'cantidadCupones_' + index"
                    class="form-control"
                    v-model="cupon.cantidadCupones"
                    min="100"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="cantidadPorCuponera"
                    >3. Cantidad de veces que un usuario puede hacer válido este
                    cupón, por cuponera comprada *</label
                  >
                  <input
                    type="number"
                    :id="'cantidadPorCuponera_' + index"
                    class="form-control"
                    v-model="cupon.cantidadPorCuponera"
                    min="1"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="cantidadPorDia"
                    >4. Cantidad de veces que un usuario puede hacer válido este
                    cupón por día, por cuponera comprada *</label
                  >
                  <input
                    type="number"
                    :id="'cantidadPorDia_' + index"
                    class="form-control"
                    v-model="cupon.cantidadPorDia"
                    min="1"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="imagenes"
                    >5. Máximo 2 imágenes de los productos o servicios que se
                    ofrecen en el cupón, medida 600 x 600px *</label
                  >

                  <S3FilesUpload
                    :obj="cupones[index].imagenes"
                    fieldName="imageUrl"
                    directory="coupon"
                    label="imagenes 600x600 máximo"
                    :set-image-upload="setImageUpload"
                    :set-error-message="setError"
                    :set-persist-error="setPersistError"
                    :width="600"
                    :height="600"
                    :item="company"
                    :cuponIndex="index"
                  />
                </div>

                <div class="form-group">
                  <label for="slogan"
                    >6. Frase de apoyo sobre el producto/servicio
                    (slogan)*</label
                  >
                  <input
                    type="text"
                    :id="'slogan_' + index"
                    class="form-control"
                    v-model="cupon.slogan"
                    maxlength="25"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="terminos"
                    >7. Términos y condiciones de la promoción o cupón*</label
                  >
                  <textarea
                    :id="'terminos_' + index"
                    class="form-control"
                    v-model="cupon.terminos"
                    rows="4"
                    maxlength="4000"
                    required
                  ></textarea>
                </div>

                <div class="form-group">
                  <label for="fechaInicio"
                    >8. Fecha de inicio de la promoción*</label
                  >
                  <input
                    type="date"
                    :id="'fechaInicio_' + index"
                    class="form-control"
                    v-model="cupon.fechaInicio"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="fechaExpiracion"
                    >9. Fecha de expiración de la promoción *</label
                  >
                  <input
                    type="date"
                    :id="'fechaExpiracion_' + index"
                    class="form-control"
                    v-model="cupon.fechaExpiracion"
                    required
                  />
                </div>

                <div class="form-group">
                  <label
                    >10. Seleccionar sucursal(es) donde se podrá hacer válido
                    esta promoción. (al menos una sucursal)*</label
                  >
                  <div v-for="(sucursal, i) in sucursales" :key="i">
                    <label :for="'sucursalCheckbox-' + index">
                      <input
                        type="checkbox"
                        :id="'sucursalCheckbox-' + index"
                        v-model="cupon.sucursales"
                        :value="i"
                      />
                      {{ sucursal.nombre }}
                    </label>
                  </div>
                </div>

                <button
                  v-if="index > 0"
                  class="btn btn-danger btn-sm"
                  @click="removeCupon(index)"
                >
                  Eliminar Cupón
                </button>

                <hr />
              </div>

              <button
                class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded float-left"
                @click="addCupon"
              >
                Agregar Cupón
              </button>
            </form>
          </div>
        </div>

        <!-- botones -->
        <div class="mt-3">
          <button
            class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded float-left"
            v-if="selectedSection !== 'empresa'"
            @click="previousSection"
          >
            Anterior
          </button>

          <button
            class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded float-right"
            v-if="selectedSection !== 'cupon'"
            type="submit"
            @click="nextSection"
            :disabled="!isCurrentSectionValid"
          >
            Siguiente
          </button>

          <button
            class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded float-right"
            type="submit"
            @click="submitAllForms"
            v-if="isAllFormsValid"
            :disabled="!isAllFormsValid && submitbutton"
          >
            Enviar
          </button>

          <div class="clearfix"></div>
        </div>
      </div>

      <div class="container d-flex flex-column mt-2" v-else>
        <h2 class="color-secondary">Afíliate a Becupon</h2>

        <div class="d-flex flex-column align-items-center mt-2">
          <div
            class="fa fa-circle-check icon-size-lg mr-3 inline-block mb-3"
            style="color: green; font-size: 70px"
          ></div>

          <p>
            Datos enviados correctamente, nuestro equipo evaluara su propuesta y
            se comunicara a la brevedad, muchas gracias.
          </p>

          <!-- <div class="mb-2 d-flex flex-column align-items-center">
            <p>
              Realice el pago de su paquete seleccionado a través del siguiente
              enlace:
            </p>

            <button
              class="g-recaptcha d-flex align-items-center app-download-btn btn btn-success btn-rounded"
              @click=""
            >
              Pagar
            </button>
          </div> -->

          <button
            class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded float-right"
            @click="goToHomePage"
          >
            Volver
          </button>
        </div>
      </div>
    </body>

    <site-footer-details :show-subscribe="true" />
    <copyright />
  </div>
</template>

<script>
import NavBar from "../views/commons/NavBar";
import SiteFooterDetails from "../components/becuponDetails/SiteFooterDetails";
import Copyright from "../views/commons/Copyright";
import axios from "axios";
import S3FileUpload from "../components/S3FileUpload.vue";
import S3FilesUpload from "../components/S3FilesUpload.vue";

const url = process.env.VUE_APP_GRAPHQL_HTTP;

export default {
  name: "AfiliadosPage",

  data() {
    return {
      selectedSection: "empresa",
      showAdminPassword: false,
      showEmpleadoPassword: false,
      completedSections: [],
      paquetes: [
        {
          id: 1,
          name: "Paquete 1",
          description: "Forma parte de 3 cuponeras",
        },
        {
          id: 2,
          name: "Paquete 2",
          description:
            "Cuponeras + Publicidad Be Cupon Facebook: 1 post, 4 historias Instagram: 1 post, 4 historias Yo Amo Tijuana Facebook: 1 historia, 1 post Instagram: 1 historia con mención TJ Noticias Facebook: 1 historia, 1 post Instagram: 1 historia con mención",
        },
        {
          id: 3,
          name: "Paquete 3",
          description:
            "Cuponeras + Publicidad Be Cupon Facebook: 1 post, 4 historias Instagram: 1 post, 4 historias 1 Banner en la pagina web Yo Amo Tijuana Facebook: 1 historia, 1 post Instagram: 1 historia con mención 1 carrusel, 1 reel TJ Noticias Facebook: 1 historia, 1 post Instagram: 1 historia con mención 1 carrusel",
        },
        {
          id: 4,
          name: "Paquete 4",
          description:
            "Cuponeras + publicidad Be Cupon Facebook: 1 post, 4 historias Instagram: 1 post, 4 historias 1 banner en la parte superior de la App Activación en eventos masivos de espectáculos o deportivos de las compañías The Crash e In House. Notificaciones Push Yo Amo Tijuana Facebook: 1 historia, 1 post, menciones en el noticiero. Instagram: 1 historia con mención, 1 carrusel. Tj Noticias Facebook: 1 historia, 1 post, menciones en el noticiero. Instagram: 1 historia con mención, 1 carrusel. Clima Tijuana Facebook: Patrocinadora del clima del día.",
        },
      ],
      company: {},

      empresa: {
        nombre: "",
        descripcion: "",
        horarioDesde: "",
        horarioHasta: "",
        telefono: "",
        logo: {
          logoUrl: "",
        },
        id: -1,
      },

      sucursales: [
        {
          nombre: "",
          domicilio: "",
          horarioDesde: "",
          horarioHasta: "",
          telefono: "",
          id: "",
        },
      ],

      paqueteSeleccionado: "",

      admin: {
        nombre: "",
        apellido: "",
        pais: "México",
        estado: "",
        ciudad: "",
        correo: "",
        contrasena: "",
        id: "",
      },

      empleados: [
        {
          nombre: "",
          apellido: "",
          correo: "",
          contrasena: "",
          sucursal: "",
          id: "",
        },
      ],

      cupones: [
        {
          nombrePromocion: "",
          cantidadCupones: 100,
          cantidadPorCuponera: 1,
          cantidadPorDia: 1,
          imagenes: {},
          slogan: "",
          terminos: "",
          fechaInicio: null,
          fechaExpiracion: null,
          sucursales: [],
        },
      ],

      errorMessage: "",
      hasError: false,
      dealId: 0,
      adminError: "",
      employmentError: "",

      empresaOk: false,
      sucursalesOk: false,
      paqueteOk: false,
      adminOk: false,
      empleadosOk: false,
      cuponsOk: false,

      submitbutton: true,

      affiliationOk: false,
    };
  },

  methods: {
    goToHomePage() {
      this.$router.push("/");
    },

    setLogoUpload(url) {
      this.empresa.logo.logoUrl = url;
    },

    setImageUpload(images, cuponIndex) {
      this.cupones[cuponIndex].imagenes = images;
    },

    setError(message) {
      this.hasError = true;
      this.dialog = true;
      this.errorMessage = message;
      this.persistError = true;
    },

    setPersistError(error) {
      this.persistError = error;
    },

    validarFechas(cupon) {
      const fechaActual = new Date();
      if (cupon.fechaInicio <= fechaActual) {
        alert("La fecha de inicio debe ser posterior a la fecha actual.");
        return false;
      }

      if (cupon.fechaExpiracion <= cupon.fechaInicio) {
        alert(
          "La fecha de expiración debe ser posterior a la fecha de inicio."
        );
        return false;
      }

      return true;
    },

    previousSection() {
      const sections = [
        "empresa",
        "sucursal",
        "paquete",
        "admin",
        "empleado",
        "cupon",
      ];
      const currentIndex = sections.indexOf(this.selectedSection);
      if (currentIndex > 0) {
        this.selectedSection = sections[currentIndex - 1];
      }
    },

    nextSection() {
      const sections = [
        "empresa",
        "sucursal",
        "paquete",
        "admin",
        "empleado",
        "cupon",
      ];

      this.completedSections.push(this.selectedSection);

      const currentIndex = sections.indexOf(this.selectedSection);
      if (currentIndex < sections.length - 1) {
        this.selectedSection = sections[currentIndex + 1];
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    async submitEmpresaForm() {
      if (this.isEmpresaFormValid) {
        // Envía el formulario

        try {
          const logoResponse = await axios.post(
            url,
            {
              query: `mutation {
              createImages(images: ["${this.empresa.logo.logoUrl}"]){
                ok
                imagesIds
              }
            }`,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (logoResponse.data && logoResponse.data.data.createImages.ok) {
            // console.log(logoResponse.data.data);

            // Segunda solicitud Axios
            const companyResponse = await axios.post(
              url,

              `mutation {
                createCompany(newCompany: {
                  logo: ${logoResponse.data.data.createImages.imagesIds[0]}
                  name: "${this.empresa.nombre}"
                  description: "${this.empresa.descripcion}"
                  openTime: "${this.empresa.horarioDesde}"
                  closeTime: "${this.empresa.horarioHasta}"
                  phoneNumber: "${this.empresa.telefono}"
                  notifAuth: false
                  affiliate: false
              }) {
                ok
                company{
                  id
                  name
                }
              }
            }`,
              {
                headers: {
                  "Content-Type": "application/graphql",
                },
              }
            );

            if (
              companyResponse.data &&
              companyResponse.data.data.createCompany.ok
            ) {
              // console.log(companyResponse.data.data);

              this.empresa.id =
                companyResponse.data.data.createCompany.company.id;

              this.empresaOk = true;
            }
          } else {
            // La solicitud falló, puedes mostrar un mensaje de error
            alert("La solicitud ha fallado. Por favor, inténtelo de nuevo.");
          }
        } catch (error) {
          console.error(
            "Error al realizar la solicitud Axios en empresa:",
            error
          );
          alert("Ha ocurrido un error al procesar la solicitud.");
        }

        // ...
      } else {
        // Muestra un mensaje de error o realiza alguna acción en caso de formulario inválido
        alert("Por favor, complete todos los campos obligatorios.");
      }
    },

    async submitSucursalForm() {
      if (this.isSucursalFormValid) {
        // Guarda los datos de la sucursal

        const sucursales = this.sucursales;

        try {
          for (const sucursal of sucursales) {
            const response = await axios.post(
              url,

              `mutation {
                createBranchOffice(newBranchoffice: {
                  company: ${this.empresa.id},
                  name: "${sucursal.nombre}",
                  openTime: "${sucursal.horarioDesde}",
                  closeTime: "${sucursal.horarioHasta}",
                  phoneNumber: "${sucursal.telefono}",
                  address: "${sucursal.domicilio}",
                  affiliate: false
                }) {
                  ok
                  branchoffice {
                    id
                    name
                  }
                }
              }`,

              {
                headers: {
                  "Content-Type": "application/graphql",
                },
              }
            );

            if (response.data && response.data.data.createBranchOffice.ok) {
              // Éxito: Puedes realizar alguna acción en caso de éxito para esta sucursal
              // console.log(
              //   `Sucursal "${sucursal.nombre}" creada exitosamente:`,
              //   response.data.data
              // );

              const createdSucursalId =
                response.data.data.createBranchOffice.branchoffice.id;
              // Encuentra el índice de la sucursal actual en this.sucursales
              const index = sucursales.findIndex(
                (suc) => suc.nombre === sucursal.nombre
              );

              if (index !== -1) {
                // Actualiza la propiedad "id" de la sucursal en this.sucursales
                this.sucursales[index].id = createdSucursalId;
                // console.log(
                //   `Sucursal "${sucursal.nombre}" creada exitosamente con ID: ${createdSucursalId}`
                // );
              } else {
                console.error(
                  `No se encontró la sucursal "${sucursal.nombre}" en this.sucursales.`
                );
              }

              this.sucursalesOk = true;
            } else {
              console.error(
                `Error al crear la sucursal "${sucursal.nombre}":`,
                response.data
              );

              this.sucursalesOk = false;
            }
          }
        } catch (error) {
          console.error("Error al realizar las solicitudes Axios:", error);
          alert("Ha ocurrido un error al procesar las solicitudes.");
        }
        // ...
      } else {
        // Muestra un mensaje de error o realiza alguna acción en caso de formulario inválido
        alert("Por favor, complete todos los campos obligatorios.");
      }
    },

    async submitPaqueteForm() {
      if (this.isPaqueteFormValid) {
        // Procesa el paquete seleccionado

        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const nextMonth = String(
          today.getMonth() + 1 < 12 ? today.getMonth() + 2 : 1
        ).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        const dateOff = `${year}-${nextMonth}-01`;

        try {
          const response = await axios.post(
            url,

            `mutation {
              createDeal(newDeal: {
                name: "${this.empresa.nombre}",
                dateStart: "${formattedDate}",
                dateOff: "${dateOff}",
                company: ${this.empresa.id},
                services: ${this.paqueteSeleccionado}
              }) {
                ok
                deal {
                  id
                  name
                }
              }
          }`,

            {
              headers: {
                "Content-Type": "application/graphql",
              },
            }
          );

          if (response.data && response.data.data.createDeal.ok) {
            // console.log(
            //   `Deal "${response.data.data.createDeal.deal.name}" creada exitosamente:`,
            //   response.data.data
            // );

            this.dealId = response.data.data.createDeal.deal.id;

            this.paqueteOk = true;
          } else {
            console.error(
              `Error al crear el deal "${this.empresa.nombre}":`,
              response.data
            );
          }
        } catch (error) {
          console.error("Error al realizar las solicitudes Axios:", error);
          alert("Ha ocurrido un error al procesar las solicitudes.");
        }

        // Continúa con la lógica de navegación o envío de datos, según sea necesario
      } else {
        // Muestra un mensaje de error en caso de formulario inválido
        alert("Por favor, seleccione un paquete.");
      }
    },

    async submitAdminForm() {
      if (this.isAdminFormValid) {
        // Procesa el formulario del administrador de la empresa

        try {
          const userExistResponse = await axios.post(
            url,

            `
            {
              userByEmail(email: "${this.admin.correo}"){
                id
                username
                email
                firstName
                lastName
                isActive
              }
            }`,

            {
              headers: {
                "Content-Type": "application/graphql",
              },
            }
          );

          if (
            userExistResponse.data &&
            !userExistResponse.data.data.userByEmail
          ) {
            const createUserResponse = await axios.post(
              url,

              `mutation {
                createUser(newCustomuser:{
                  password: "${this.admin.contrasena}",
                  email: "${this.admin.correo}",
                  username: "${this.admin.correo}",
                  firstName: "${this.admin.nombre}",
                  lastName: "${this.admin.apellido}",
                  isEmailConfirmed: true,
                  isActive: true
                }) {
                  ok
                  customuser{
                    id
                  }
                }
              }`,

              {
                headers: {
                  "Content-Type": "application/graphql",
                },
              }
            );
            // console.log(createUserResponse);

            if (
              createUserResponse.data &&
              createUserResponse.data.data.createUser.ok
            ) {
              this.admin.id =
                createUserResponse.data.data.createUser.customuser.id;

              const createManagerResponse = await axios.post(
                url,

                `mutation {
                    createManager(newManager:{
                      user: ${this.admin.id},
                      company: ${this.empresa.id}
                    }){
                      ok
                      manager{
                        id
                      }
                    }
                  }`,

                {
                  headers: {
                    "Content-Type": "application/graphql",
                  },
                }
              );

              if (
                createManagerResponse.data &&
                createManagerResponse.data.data.createManager.ok
              ) {
                // console.log(
                //   `Admin "${this.admin.correo}" creada exitosamente:`,
                //   createManagerResponse.data.data
                // );

                this.adminOk = true;
              } else {
                this.adminError = "Error al asignar el usuario admin";
              }
            } else {
              this.adminError = "Error al crear el usuario admin";

              console.log(this.adminError);
            }
          } else {
            this.adminError =
              "Correo ya registrado como usuario previamente, ingrese un correo diferente.";

            console.log(this.adminError);
          }
        } catch (error) {
          console.log(error);
        }
        // ...
      } else {
        // Muestra un mensaje de error en caso de formulario inválido
        alert(
          "Por favor, complete todos los campos obligatorios correctamente."
        );
      }
    },

    async submitEmpleadoForm() {
      if (this.isEmpleadoFormValid) {
        // Procesa el formulario del empleado autorizado

        const empleados = this.empleados;

        try {
          for (const empleado of empleados) {
            const userExistResponse = await axios.post(
              url,

              `
            {
              userByEmail(email: "${empleado.correo}"){
                id
                username
                email
                firstName
                lastName
                isActive
              }

            }`,

              {
                headers: {
                  "Content-Type": "application/graphql",
                },
              }
            );

            if (
              userExistResponse.data &&
              !userExistResponse.data.data.userByEmail
            ) {
              const createUserResponse = await axios.post(
                url,

                `mutation {
                createUser(newCustomuser:{
                  password: "${empleado.contrasena}",
                  email: "${empleado.correo}",
                  username: "${empleado.correo}",
                  firstName: "${empleado.nombre}",
                  lastName: "${empleado.apellido}",
                  isEmailConfirmed: true,
                  isActive: true
                }) {
                  ok
                  customuser{
                    id
                  }
                }
              }`,

                {
                  headers: {
                    "Content-Type": "application/graphql",
                  },
                }
              );

              if (
                createUserResponse.data &&
                createUserResponse.data.data.createUser.ok
              ) {
                const sucursal = this.sucursales.find((sucursal) => {
                  return sucursal.nombre == empleado.sucursal;
                });

                const createdEmploymentId =
                  createUserResponse.data.data.createUser.customuser.id;

                const index = empleados.findIndex(
                  (emp) => emp.nombre === empleado.nombre
                );

                if (index !== -1) {
                  // Actualiza la propiedad "id" del empleado en this.empleados
                  this.empleados[index].id = createdEmploymentId;
                  // console.log(
                  //   `empleado "${empleado.nombre}" creada exitosamente con ID: ${createdEmploymentId}`
                  // );
                } else {
                  console.error(
                    `No se encontró la empleado "${empleado.nombre}" en this.empleados.`
                  );
                }

                const createEmploymentResponse = await axios.post(
                  url,

                  `mutation {
                    createEmployment(newEmployment:{
                      user: ${createdEmploymentId},
                      branchOffice: ${sucursal.id}
                    }){
                      ok
                      employment{
                        id
                      }
                    }
                  }`,

                  {
                    headers: {
                      "Content-Type": "application/graphql",
                    },
                  }
                );

                if (
                  createEmploymentResponse.data &&
                  createEmploymentResponse.data.data.createEmployment.ok
                ) {
                  // console.log(
                  //   `Empleado creado correctamente`,
                  //   createEmploymentResponse.data.data
                  // );

                  this.empleadosOk = true;
                } else {
                  this.employmentError = "Error al asignar el empleado";
                  this.empleadosOk = false;

                  console.log(this.employmentError);
                }
              } else {
                this.employmentError = "Error al crear el usuario empleado";

                console.log(this.employmentError);
              }
            } else {
              this.employmentError =
                "Correo ya registrado como usuario previamente, ingrese un correo diferente.";

              console.log(this.employmentError);
            }
          }
        } catch (error) {
          console.log(error);
        }
        // ...
      } else {
        // Muestra un mensaje de error en caso de formulario inválido
        alert(
          "Por favor, complete todos los campos obligatorios correctamente."
        );
      }
    },

    async submitCuponForm() {
      // Validar cada cupón en el array de cupones
      for (const cupon of this.cupones) {
        if (!this.isCuponValid(cupon)) {
          // Mostrar un mensaje de error si un cupón no es válido
          alert(
            "Por favor, complete todos los campos obligatorios correctamente."
          );
          return;
        }

        if (!this.validarFechas(cupon)) {
          // Mostrar un mensaje de error si las fechas no son válidas
          alert("Por favor, ingrese una fecha válida.");
          return;
        }
      }

      if (this.isCuponFormValid) {
        const cupones = this.cupones;

        try {
          for (const cupon of cupones) {
            const imagesResponse = await axios.post(
              url,
              {
                query: `
              mutation createImages($imageUrls: [String]) {
                createImages(images: $imageUrls) {
                  ok
                  imagesIds
                }
              }`,

                variables: {
                  imageUrls: cupon.imagenes.slice(0, 2),
                },
              },

              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (
              imagesResponse.data &&
              imagesResponse.data.data.createImages.ok
            ) {
              const images = imagesResponse.data.data.createImages.imagesIds;

              const sucursalesIds = [];

              for (const sucursalIndex of cupon.sucursales) {
                const sucursal = this.sucursales[sucursalIndex];

                if (sucursal) {
                  sucursalesIds.push(sucursal.id);
                }
              }

              const createCouponResponse = await axios.post(
                url,
                {
                  query: `
                mutation createCoupon($imageUrls: [ID!]!, $sucursalesIds: [ID!]!){
                  createCoupon(newCoupon:{
                    images: $imageUrls,
                    title: "${cupon.nombrePromocion}",
                    subTitle: "${cupon.slogan}",
                    description: "${cupon.terminos}",
                    initialDate: "${cupon.fechaInicio}",
                    expirationDate: "${cupon.fechaExpiracion}",
                    company: ${this.empresa.id},
                    totalUses: ${cupon.cantidadCupones},
                    dailyUses: ${cupon.cantidadPorCuponera},
                    maxPerson: ${cupon.cantidadPorDia},
                    showApp: false,
                    active: false,
                    affiliate: false,
                    branchOffices: $sucursalesIds
                  }){
                    ok
                    coupon{
                      id
                      title
                    }
                  }
                }`,

                  variables: {
                    imageUrls: images,
                    sucursalesIds: sucursalesIds,
                  },
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              if (
                createCouponResponse.data &&
                createCouponResponse.data.data.createCoupon.ok
              ) {
                // console.log("Cupon creado exitosamente");
                this.cuponsOk = true;
              } else {
                this.cuponsOk = false;

                // La solicitud falló, puedes mostrar un mensaje de error
                alert(
                  "La solicitud ha fallado. Por favor, inténtelo de nuevo."
                );
              }
            } else {
              // La solicitud falló, puedes mostrar un mensaje de error
              alert("La solicitud ha fallado. Por favor, inténtelo de nuevo.");
            }
          }
        } catch (error) {
          console.log(error);
        }
      }

      // Procesar el formulario de cupones si todos los cupones son válidos
      // ...
    },

    async submitAllForms() {
      this.submitbutton = false;

      try {
        await this.submitEmpresaForm();

        if (this.empresaOk) {
          await this.submitSucursalForm();

          if (this.sucursalesOk) {
            await this.submitPaqueteForm();

            if (this.paqueteOk) {
              await this.submitAdminForm();

              if (this.adminOk) {
                await this.submitEmpleadoForm();

                if (this.empleadosOk) {
                  await this.submitCuponForm();

                  if (this.cuponsOk) {
                    this.affiliationOk = true;
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.error("Error en la secuencia de envío:", error);
        alert(
          "Ha ocurrido un error en la secuencia de envío, vuelva a intentarlo más tarde."
        );
      }
    },

    isCuponValid(cupon) {
      // Validar un cupón individual
      return (
        cupon.nombrePromocion &&
        cupon.cantidadCupones >= 100 &&
        cupon.cantidadPorCuponera &&
        cupon.cantidadPorDia &&
        cupon.imagenes.length >= 1 && // Al menos una imagen cargada
        cupon.slogan &&
        cupon.terminos &&
        cupon.fechaInicio &&
        cupon.fechaExpiracion &&
        cupon.sucursales.length >= 1 // Al menos una sucursal seleccionada
      );
    },

    allowNumbersOnly(event) {
      // Esta función permite solo números en el campo de teléfono
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },

    addSucursal() {
      this.sucursales.push({
        nombre: "",
        domicilio: "",
        horarioDesde: "",
        horarioHasta: "",
        telefono: "",
        // encargado: "",
      });
    },

    removeSucursal(index) {
      // Elimina la sucursal en el índice especificado
      if (index > 0 && index < this.sucursales.length) {
        this.sucursales.splice(index, 1);
      }
    },

    addEmpleado() {
      this.empleados.push({
        nombre: "",
        apellido: "",
        // telefonoSucursal: "",
        // pais: "México",
        // estado: "",
        // ciudad: "",
        correo: "",
        contrasena: "",
        sucursal: "",
      });
    },

    addCupon() {
      this.cupones.push({
        nombrePromocion: "",
        cantidadCupones: 100,
        cantidadPorCuponera: 1,
        cantidadPorDia: 1,
        imagenes: {},
        slogan: "",
        terminos: "",
        fechaInicio: null,
        fechaExpiracion: null,
        sucursales: [],
      });
    },

    removeEmpleado(index) {
      // Elimina el empleado en el índice especificado
      if (index > 0 && index < this.empleados.length) {
        this.empleados.splice(index, 1);
      }
    },

    removeCupon(index) {
      // Elimina el cupon en el índice especificado
      if (index > 0 && index < this.cupones.length) {
        this.cupones.splice(index, 1);
      }
    },

    correoEmpleadoInvalido(index) {
      // Verifica si el correo de algún empleado coincide con el del admin o con otro empleado
      const empleadoCorreo = this.empleados[index].correo;

      // Verificar que el correo del empleado coincida con el del admin
      if (empleadoCorreo === this.admin.correo) {
        return "admin";
      }

      // Verificar que el correo del empleado coincida con el de otros empleados
      const otrosEmpleados = this.empleados.filter(
        (empleado, idx) => idx !== index
      );
      if (
        otrosEmpleados.some(
          (otroEmpleado) => otroEmpleado.correo === empleadoCorreo
        )
      ) {
        return "otroEmpleado";
      }

      // Si no coincide con el admin ni con otros empleados, devuelve null
      return null;
    },
  },

  async mounted() {
    await axios
      .post(url, {
        query: `
      {
  serviceList{
    results{
      id
      name
      description
      active
      price
      formAfiliate
    }
  }
}
      `,
      })
      .then((response) => {
        this.paquetes = response.data.data.serviceList.results.filter(
          (paquete) => {
            return paquete.active === true && paquete.formAfiliate === true;
          }
        );
      });
  },

  computed: {
    isAllFormsValid() {
      return (
        this.isEmpresaFormValid &&
        this.isSucursalFormValid &&
        this.isEmpleadoFormValid &&
        this.isPaqueteFormValid &&
        this.isAdminFormValid &&
        this.isCuponFormValid
      );
    },

    isEmpresaFormValid() {
      // Aquí realizamos la validación del formulario actual
      return (
        this.empresa.nombre &&
        this.empresa.descripcion &&
        // this.empresa.domicilio &&
        // this.empresa.estado &&
        // this.empresa.ciudad &&
        this.empresa.horarioDesde &&
        this.empresa.horarioHasta &&
        this.empresa.telefono &&
        this.empresa.logo.logoUrl
      );
    },

    isSucursalFormValid() {
      return (
        this.sucursales.length > 0 &&
        this.sucursales.every((sucursal) => {
          return (
            sucursal.nombre &&
            sucursal.domicilio &&
            sucursal.horarioDesde &&
            sucursal.horarioHasta &&
            sucursal.telefono
          );
        })
      );
    },

    isEmpleadoFormValid() {
      return (
        this.empleados.length > 0 &&
        this.empleados.every((empleado) => {
          return (
            empleado.nombre &&
            empleado.correo &&
            empleado.contrasena &&
            empleado.sucursal
          );
        })
      );
    },

    isPaqueteFormValid() {
      // Valida que se haya seleccionado un paquete
      return this.paqueteSeleccionado !== "";
    },

    isAdminFormValid() {
      // Realiza la validación del formulario de administrador
      return (
        this.admin.nombre &&
        this.admin.apellido &&
        // this.admin.telefono &&
        // this.admin.pais &&
        // this.admin.estado &&
        // this.admin.ciudad &&
        this.admin.correo &&
        this.admin.contrasena
      );
    },

    isCuponFormValid() {
      // Validación del formulario de cupones
      return this.cupones.every((cupon) => {
        return (
          cupon.nombrePromocion &&
          cupon.cantidadCupones >= 100 &&
          cupon.cantidadPorCuponera &&
          cupon.cantidadPorDia &&
          cupon.imagenes.length > 0 &&
          cupon.slogan &&
          cupon.terminos &&
          cupon.fechaInicio &&
          cupon.fechaExpiracion &&
          cupon.sucursales.length > 0
        );
      });
    },

    isCurrentSectionValid() {
      switch (this.selectedSection) {
        case "empresa":
          return this.isEmpresaFormValid;
        case "sucursal":
          // Validar que al menos una sucursal tenga datos
          return this.isSucursalFormValid;

        case "paquete":
          return this.isPaqueteFormValid;

        case "admin":
          return this.isAdminFormValid;

        case "empleado":
          return this.isEmpleadoFormValid;

        case "cupon":
          return this.isCuponFormValid;

        // Agrega casos para otras secciones aquí si es necesario
        default:
          return true; // Si la sección no tiene validación específica, considerarla válida
      }
    },
  },

  components: {
    NavBar,
    SiteFooterDetails,
    Copyright,
    S3FileUpload,
    S3FilesUpload,
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}

.hide-on-mobile {
  display: inline-block; /* Por defecto, mostrar en dispositivos con un ancho de pantalla de al menos 768px */
}

.activee {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  background-color: green;
  border-radius: 50% !important;
  font-weight: bold;
  text-align: center;
  color: white;
  width: 40px;
  height: 40px;
}

.inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  background-color: darkgray;
  border-radius: 50% !important;
  font-weight: bold;
  text-align: center;
  width: 40px;
  height: 40px;
  color: white;
}

.completed {
  background-color: lightgreen;
  color: darkgreen;
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>
