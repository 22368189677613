<template>
  <div class="overflow-hidden">
    <section
      id="somos-becupon"
      class="about-us ptb-100 background-shape-img position-relative"
      v-bind:class="{ 'gray-light-bg': isGray }"
    >
      <div class="animated-shape-wrap">
        <div class="animated-shape-item"></div>
        <div class="animated-shape-item"></div>
        <div class="animated-shape-item"></div>
        <div class="animated-shape-item"></div>
        <div class="animated-shape-item"></div>
      </div>
      <div class="container">
        <div
          class="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center"
        >
          <div class="col-md-12 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
            <div class="about-content-left">
              <h2>
                Digitalizamos las cuponeras tradicionales para que ahorres con
                un clic.
              </h2>
              <ul class="dot-circle pt-3">
                <li>Desarrollamos estrategias para apoyar tu economía.</li>
                <li>Ofrecemos precios accesibles.</li>
                <li>Asociamos a los mejores comercios de la región.</li>
                <li>Somos una App de navegación práctica y sencilla.</li>
                <li>
                  Somos una empresa verde que reduce la deforestación y
                  contaminación.
                </li>
              </ul>
              <div class="row pt-3">
                <div class="col-6 col-lg-6 border-right">
                  <div class="count-data text-left">
                    <h4
                      class="count-number mb-0 color-primary font-weight-bold"
                    >
                      Visión
                    </h4>
                    <span
                      >Ser la aplicación de referencia regional, reconocida a
                      nivel nacional, por implementar la digitalización de
                      cuponeras tradicionales
                    </span>
                  </div>
                </div>
                <div class="col-6 col-lg-6 border-right">
                  <div class="count-data text-left">
                    <h4
                      class="count-number mb-0 color-primary font-weight-bold"
                    >
                      Misión
                    </h4>
                    <span
                      >Fortalecer la relación entre establecimientos comerciales
                      y consumidores haciendo más eficiente el acceso a
                      promociones, descuentos, lanzamiento de nuevos productos y
                      programas de lealtad.</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-5 col-md-5 col-lg-4">
            <div class="about-content-right">
              <img
                src="../../assets/img/app-mobile-image-6.png"
                alt="Sobre BeCupon"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "About",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
