<template>
  <section
    id="contacto"
    class="contact-us-section ptb-100"
    v-bind:class="{ 'gray-light-bg': isGray }"
  >
    <div class="container">
      <div class="row justify-content-around">
        <div
          class="col-12 pb-3 message-box"
          v-bind:class="{
            'd-none': !isSuccess && !hasError,
            'd-block': isSuccess || hasError,
          }"
        >
          <div
            class="alert"
            v-bind:class="{
              'alert-danger': hasError,
              'alert-success': isSuccess,
            }"
          >
            {{ alertText }}
          </div>
        </div>

        <div class="col-md-12 col-lg-6">
          <div class="contact-us-content">
            <!-- <h2>
              Si estás interesado en asociar tu negocio a BeCupon envíanos tus
              datos.
            </h2> -->

            <h2>
              Si estás interesado en formar parte de BeCupon, llena este
              <router-link to="/formulario_de_afiliado">
                formulario
              </router-link>
              o envía un correo a
              <a href="mailto:ventas@becupon.com"> ventas@becupon.com </a>
            </h2>
            <hr class="my-5" />
            <ul class="contact-info-list">
              <li class="d-flex pb-3">
                <div class="contact-icon mr-3">
                  <span
                    class="fas fa-location-arrow color-primary rounded-circle p-3"
                  ></span>
                </div>
                <div class="contact-text">
                  <h5 class="mb-1">Ubicación</h5>
                  <p>Tijuana, Baja California, México</p>
                </div>
              </li>
              <li class="d-flex pb-3">
                <div class="contact-icon mr-3">
                  <span
                    class="fas fa-envelope color-primary rounded-circle p-3"
                  ></span>
                </div>
                <div class="contact-text">
                  <h5 class="mb-1">Correo electrónico</h5>
                  <a href="mailto:ventas@becupon.com">
                    <p>ventas@becupon.com</p>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
          <div class="contact-us-form gray-light-bg rounded p-5">
            <h4>¿Listo para charlar con nosotros?</h4>
            <form v-on:submit="submit" id="contactForm" class="contact-us-form">
              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      placeholder="Nombre completo"
                      required="required"
                      v-model="name"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="Correo electrónico"
                      required="required"
                      v-model="email"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <input
                      type="number"
                      class="form-control"
                      name="telefono"
                      placeholder="Número de teléfono"
                      required="required"
                      v-model="telefono"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <textarea
                      name="message"
                      id="message"
                      class="form-control"
                      rows="7"
                      cols="25"
                      placeholder="Cuéntanos sobre tu negocio"
                      v-model="message"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <div id="recaptcha" ref="recaptcha"></div>
                </div>

                <div class="col-sm-12 mt-3">
                  <button
                    @click="submit"
                    class="btn btn-brand-02"
                    id="btnContactUs"
                  >
                    Enviar mensaje
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script src="https://www.google.com/recaptcha/api.js?render=6LejtuQmAAAAALNC8iO0NvlZ4HmMJkTGFH1EwJaW"></script>

<script>
export default {
  name: "Contact",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",
      message: "",
      name: "",
      telefono: "",
      alertText: "",
      hasError: false,
      isSuccess: false,
    };
  },

  methods: {
    onSubmit(token) {
      this.submitFormWithRecaptcha(token);
    },

    // onClick() {
    //   grecaptcha.execute();
    // },

    onCaptchaVerified(response) {
      if (response) {
        this.submitFormWithRecaptcha(response);
      }
    },

    loadRecaptcha() {
      const siteKey = "6LejtuQmAAAAALNC8iO0NvlZ4HmMJkTGFH1EwJaW";
      grecaptcha.ready(() => {
        grecaptcha.execute(siteKey, { action: "submit" }).then((token) => {
          this.submitFormWithRecaptcha(token);
        });
      });
    },

    submitFormWithRecaptcha(token) {
      if (!this.name || !this.message || !this.email || !this.telefono) {
        this.alertText = "Por favor, completa todos los campos.";
        this.isSuccess = false;
        this.hasError = true;
        return;
      }

      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("message", this.message);
      formData.append("telefono", this.telefono);
      formData.append("email", this.email);
      formData.append("g-recaptcha-response", token); // Agrega el token reCAPTCHA

      fetch("/php/contact-form-process.php", {
        body: formData,
        method: "POST",
      })
        .then((response) => {
          if (response.status === 200) {
            this.name = "";
            this.message = "";
            this.email = "";
            this.telefono = "";
            this.alertText = "Formulario enviado exitosamente";
            this.hasError = false;
            this.isSuccess = true;
          } else {
            this.alertText =
              "Se encontró un error en el formulario. Por favor, verifica nuevamente.";
            this.isSuccess = false;
            this.hasError = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.alertText =
            "Se encontró un error en el formulario. Por favor, verifica nuevamente.";
          this.isSuccess = false;
          this.hasError = true;
        });
    },

    // submit: function (e) {
    //   e.preventDefault();

    //   let formData = new FormData();
    //   formData.append("name", this.name);
    //   formData.append("message", this.message);
    //   formData.append("telefono", this.telefono);
    //   formData.append("email", this.email);

    //   fetch("/php/contact-form-process.php", {
    //     body: formData,
    //     method: "POST",
    //   })
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.name = "";
    //         this.message = "";
    //         this.email = "";
    //         this.telefono = "";
    //         this.alertText = "Form submitted successfully";
    //         this.hasError = false;
    //         this.isSuccess = true;
    //       } else {
    //         this.alertText = "Found error in the form. Please check again.";
    //         this.isSuccess = false;
    //         this.hasError = true;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.alertText = "Found error in the form. Please check again.";
    //       this.isSuccess = false;
    //       this.hasError = true;
    //     });
    // },

    submit(e) {
      e.preventDefault();
      this.loadRecaptcha();
    },
  },
  mounted() {
    this.alertText = "";
    this.hasError = false;
    this.isSuccess = false;
  },
};
</script>
