<template>
  <div class="footer-bottom py-3 gray-light-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5">
          <div class="copyright-wrap small-text">
            <p class="mb-0">&copy; Todos los derechos reservados BeCupon</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-7">
          <div class="terms-policy-wrap text-lg-right text-md-right text-left">
            <ul class="list-inline">
              <li class="list-inline-item">
                <download-pdf-button
                  :pdf-url="pdfUrl"
                  :pdf-file-name="pdfFileName"
                  style="cursor: pointer"
                />
              </li>
              <li class="list-inline-item">
                <router-link class="small-text" :to="{ name: 'TerminosPage' }"
                  >Términos y Condiciones</router-link
                >
              </li>
              <li class="list-inline-item">
                <router-link class="small-text" :to="{ name: 'PoliticasPage' }"
                  >Aviso de Privacidad</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadPdfButton from "@/components/DownloadPdfButton.vue";
export default {
  name: "Copyright",

  components: {
    DownloadPdfButton,
  },
  data() {
    return {
      pdfUrl: "/BECUPON_MediaKit.pdf",
      pdfFileName: "BECUPON_MediaKit.pdf",
    };
  },
  methods: {},
};
</script>
