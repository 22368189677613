<script>
export default {
    name: 'TerminosPage',
    components: {
        NavBar, SiteFooterDetails, Copyright,
    },
    methods: {
        anterior() {
            this.$router.go(-1);
        },
    }
}
import NavBar from "../views/commons/NavBar";
import SiteFooterDetails from "../components/becuponDetails/SiteFooterDetails";
import Copyright from "../views/commons/Copyright";
</script>
<template>
    <div>
        <nav-bar />
        <body class="bg-body-tertiary page-header-section ptb-100 page-header-bg bg-image">

            <div class="container">
                <main>


                    <p style="margin-top:50pt; margin-left:35.4pt; margin-bottom:8pt; text-indent:-35.4pt; text-align:center; line-height:108%; font-size:14pt">
                        <strong>“TÉRMINOS Y CONDICIONES”</strong>
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:14pt">
                        <strong>BECUPON.COM</strong>
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
                        Primero.- Objeto
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
                        1.1<span style="width:22.07pt; display:inline-block">&#xa0;</span>El objeto de los presentes términos y condiciones es el de regular los términos de los contratos celebrados entre BeCupon y sus usuarios.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
                        1.2<span style="width:22.07pt; display:inline-block">&#xa0;</span>El usuario manifiesta que tiene facultades suficientes para suscribir el presente Contrato, y que al aceptarlo queda obligado por los presentes términos y condiciones, así como por cualquier orden de Inserción que realice rellenando y suscribiendo el formulario de suscripción on-line. 
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
                        El usuario acepta que cualquiera de sus agentes, representantes, empleados o cualquier persona jurídica que actúe en su nombre quedará sujeto y deberá cumplir los presentes términos y condiciones.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
                        1.3<span style="width:22.07pt; display:inline-block">&#xa0;</span>BeCupon.com se reserva el derecho de exclusión y veto futuro de usuarios que no acepten alguna de estas condiciones o que ofendan o engañen de cualquier en el registro sin necesidad de devolver dinero a cambio.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        1.4<span style="width:22.07pt; display:inline-block">&#xa0;</span>Los precios y promociones mencionados en la web BeCupon.com son vinculantes únicamente entre el cliente y la empresa proveedora de los productos y servicios ofertados (empresa dueña del cupón).
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        1.5<span style="width:22.07pt; display:inline-block">&#xa0;</span>Las ofertas, precios, descuentos y demás informaciones que aparecen en BeCupon.com no constituyen una oferta contractual, y se publican a efectos meramente informativos.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        1.6<span style="width:22.07pt; display:inline-block">&#xa0;</span>
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        El usuario declara que todos los datos por él indicados al registrarse son verdaderos y completos. El usuario se compromete a comunicar sin demora alguna a BeCupon.com toda modificación de sus datos de usuario.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        2. El usuario del servicio
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        2.1 El usuario reconoce que BeCupon.com es una plataforma de publicidad para personas físicas o jurídicas que acepten íntegramente el Contrato (en adelante el "Usuario”). La aceptación constituye el requisito indispensable para darse de alta como Cliente del servicio y registrarse en el mismo para acceder a ofertas.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;2.2 El usuario reconoce BeCupon tiene condiciones específicas y tiene 1 o más cupones.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        2.3 El usuario reconoce que cada cupón de un BeCupon tiene condiciones específicas y aplica restricciones de la empresa dueña del cupón.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        2.4 El usuario reconoce que la empresa BeCupon no se hace responsable si la sucursal permite el uso de fotos y screenshot al momento de usar los cupones.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        2.5 BeCupon.com, sus directivos y empleados, socios colectivos, filiales, subsidiarias, sucesores y cesionarios, así como sus agentes no serán responsables, directa o indirectamente, en manera alguna, frente a usted o cualquier otra persona por: Errores u omisiones del servicio, Retrasos, errores o interrupciones en la transmisión o entrega del servicio, pérdidas o daños ocasionados por cualquier otra razón de incumplimiento.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        2.6 Sin perjuicio de otras medidas, BeCupon podrá suspender o cerrar su cuenta en caso de sospechas fundadas (por condena, por reconocimiento, investigación por razón del seguro o del depósito, o de otro modo) que usted está involucrado en una actividad fraudulenta en relación con el sitio BeCupon.com, sin necesidad de devolver el dinero a cambio.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        2.7 El usuario reconoce que puede usar todos los cupones que tiene el BeCupon siempre y cuando el BeCupon esté vigente y se cumpla con las condiciones individuales del Cupón. 
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        3.<span style="width:27.65pt; display:inline-block">&#xa0;</span>Restricciones de uso
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        3.1<span style="width:22.07pt; display:inline-block">&#xa0;</span>No serán admitidos como usuarios los menores de dieciocho años. El usuario declara, bajo su exclusiva responsabilidad, que tiene más de dieciocho años y que toda la información que facilite se ajusta a la realidad.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        3.2<span style="width:22.07pt; display:inline-block">&#xa0;</span>Los usuarios no podrán utilizar el servicio para fines ilícitos o contradictorios con las Condiciones Generales. El usuario también acepta informar a BeCupon.com, por escrito y de manera oportuna, si observa un uso no autorizado del servicio por terceros o violaciones de derechos de autor, marcas registradas y otros derechos que el servicio pudiera estar infringiendo o viceversa.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        4.<span style="width:27.65pt; display:inline-block">&#xa0;</span>Derechos reservados
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        4.1<span style="width:22.07pt; display:inline-block">&#xa0;</span>Todos los derechos presentes y futuros relacionados con la información confidencial sobre secretos de fabricación, patentes, derechos de autor, marcas registradas, información especializada y otros derechos de propiedad amparados por las leyes y convenios internacionales, incluyendo los derechos de todas las aplicaciones y registros relacionados con el servicio (los "derechos de propiedad intelectual") presentes y futuros correspondientes al servicio (incluyendo el derecho de explotar el servicio y cualquier parte del mismo por medio de tecnologías presentes y futuras) se reservan para el uso exclusivo de BeCupon.com. Con la excepción de los casos contemplados en las Condiciones Generales, usted no puede copiar ni usar el servicio, ni partes del mismo. Usted no usará los derechos de propiedad intelectual de BeCupon.com o de terceros ni el servicio, los nombres de participantes o colaboradores del servicio, variaciones o modificaciones para ningún fin, sin la previa autorización por escrito de BeCupon.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        4.2<span style="width:22.07pt; display:inline-block">&#xa0;</span>Toda utilización de los servicios y contenidos ofrecidos en los sitios web de BeCupon.com, con la excepción de las posibilidades facilitadas por BeCupon.com, necesitará la previa autorización escrita de BeCupon.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        4.3<span style="width:22.07pt; display:inline-block">&#xa0;</span>BeCupon se reserva el derecho de eliminar o excluir de BeCupon.com a cualquier empresa dueña del cupón y/o usuario en cualquier momento y sin informarle previamente.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        5.<span style="width:27.65pt; display:inline-block">&#xa0;</span>Ofertas de las empresas dueñas de los cupones
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        5.1<span style="width:22.07pt; display:inline-block">&#xa0;</span>La empresa dueña del cupón debe tener capacidad legal para vender el/los artículos(s) o servicio (s) que inserte para su publicidad en el sitio BeCupon.com. Debe describir su artículo y todas las condiciones de la oferta en la página de inserción de ofertas del sitio becupomn.com. Sus inserciones podrán incluir textos descriptivos y fotografías. Asimismo, deberán cumplir en sus ofertas con las obligaciones de información exigidas de conformidad con la legislación vigente. 
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        Todos los artículos insertados deberán ser ofertas y descuentos incluidos en la categoría apropiada tanto geográfica como de sector comercial. También acepta que sus anuncios podrán ser accesibles desde aplicaciones de terceros proveedores de aplicaciones (como por ejemplo aplicaciones wap que permiten el acceso a los anuncios desde terminales móviles).
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        5.2<span style="width:22.07pt; display:inline-block">&#xa0;</span>Solo podrán incluirse como publicidad las ofertas especiales y descuentos sobre el precio de venta al público del mismo anunciante, no comparado con otros anunciantes, pudiendo ser de este tipo:
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        •<span style="width:30.52pt; display:inline-block">&#xa0;</span>Con la compra de un producto lleve otro ( p.e. 2 x 1:, 3x2, 4x3, etc...)
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        •<span style="width:30.52pt; display:inline-block">&#xa0;</span>Porcentaje de descuento sobre la compra de un producto ( p.e. 50%, 35%, 10%, etc...)
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        •<span style="width:30.52pt; display:inline-block">&#xa0;</span>Una Clase/Sesión/Consulta Gratuita
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        •<span style="width:30.52pt; display:inline-block">&#xa0;</span>Con la compra de un producto, otro producto X gratis.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        •<span style="width:30.52pt; display:inline-block">&#xa0;</span>Vale descuento ( p.e. 6 € con una compra superior a 25 pesos, o sobre el coste del
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        producto)
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        5.3 BeCupon se reserva el derecho de exclusión y veto futuro de ofertas que no acepten alguna de las condiciones generales o que ofendan o engañen de cualquier forma a usuarios o en el registro sin necesidad de devolver el dinero a cambio de la suspensión de días contratados y pagados.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        5.4 La Empresa dueña del cupón se hace responsable y se compromete a aceptar todos los cupones vigentes y válidos de los usuarios.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        5.5 Si la empresa dueña del cupón no hace válido el cupón válido y vigente del usuario, estará incumpliendo el contrato con BeCupon y su contrato podría ser rescindido.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        6.<span style="width:27.65pt; display:inline-block">&#xa0;</span>Protección de Datos
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        6.1<span style="width:22.07pt; display:inline-block">&#xa0;</span>BeCupon.com informa al usuario de la existencia de un fichero de datos de carácter personal creado por y para BeCupon.com y bajo su responsabilidad, con la finalidad de administrar la relación de BeCupon.com con aquellos usuarios que den datos personales y/o empresariales a BeCupon.com dando contenido a su/s página/s web.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        6.2<span style="width:22.07pt; display:inline-block">&#xa0;</span>En el momento del alta, BeCupon.com precisa de los usuarios los siguientes datos: Nombre de usuario, Nombre y apellidos de la persona de contacto, clave de acceso, número de teléfono de contacto, dirección de contacto, dirección de correo electrónico, Ningún tercero ajeno a BeCupon.com podrá acceder en ningún caso directamente, a sus datos personales. Con su alta, el usuario acepta que BeCupon.com o sus sociedades filiales o participadas le remitan información sobre los servicios y productos que puedan comercializar.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        6.3<span style="width:22.07pt; display:inline-block">&#xa0;</span>El usuario, y en su caso quien lo represente, podrá ejercitar los derechos de acceso, rectificación, oposición y cancelación por correo electrónico dirigido a info@BeCupon.com o mediante solicitud escrita y firmada dirigida a BeCupon&#xa0; al domicilio ubicado en Prol. Paseo de los Heroes 13186, 20 de noviembre, Tijuana, B.C, 22100. Dicha solicitud deberá contener los siguientes datos: nombre y apellidos del usuario personal, domicilio a efectos de notificaciones, fotocopia de IFE o pasaporte, y petición en que se concreta la solicitud. en caso de representación, deberá probarse la misma mediante documento fehaciente.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        6.4<span style="width:22.07pt; display:inline-block">&#xa0;</span>BeCupon.com señala la existencia de "cookies" en su página web.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        6.5<span style="width:22.07pt; display:inline-block">&#xa0;</span>El usuario al registrarse acepta el uso de su correo y/o nombre para que BeCupon.com pueda mandar correos electrónicos informativos. Al usar algún cupón, el usuario acepta el uso de su correo y/o nombre para que el Anunciante pueda mandar correo electrónicos informativos.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        7.<span style="width:27.65pt; display:inline-block">&#xa0;</span>Modificaciones
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        7.1<span style="width:22.07pt; display:inline-block">&#xa0;</span>BeCupon.com se reserva el derecho a modificar los servicios ofrecidos en los sitios web de BeCupon.com o a ofrecer servicios distintos, a no ser que su aceptación le sea inexigible al usuario.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        7.2<span style="width:22.07pt; display:inline-block">&#xa0;</span>BeCupon.com puede modificar las condiciones de prestación del servicio, cuando así lo aconsejen razones técnicas o por aplicación de nuevas disposiciones legales, circunstancias ambas que se expondrán en la página web de BeCupon.com.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        7.3<span style="width:22.07pt; display:inline-block">&#xa0;</span>BeCupon.com se reserva el derecho a cambiar el contenido o las especificaciones técnicas de cualquier aspecto del servicio en cualquier momento a su sola discreción. El usuario también acepta que tales cambios puedan afectar su acceso al servicio.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        8.<span style="width:27.65pt; display:inline-block">&#xa0;</span>Responsabilidad por contenidos, datos y/o información de los usuarios
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        8.1<span style="width:22.07pt; display:inline-block">&#xa0;</span>BeCupon.com no asume responsabilidad alguna por los contenidos, datos y/o información aportados por los usuarios y/o empresas dueñas de los cupones de los sitios web de BeCupon.com, ni tampoco por los contenidos de sitios web externos a los que existan enlaces. En especial BeCupon.com no garantiza que esos contenidos sean verdaderos, cumplan una determinada finalidad o puedan servir a esa finalidad.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        8.2<span style="width:22.07pt; display:inline-block">&#xa0;</span>Tan pronto el usuario advierta una utilización de los sitios web de BeCupon.com ilegal o contraria a contrato (incluida la utilización de pseudónimos o de identidades engañosas), podrá comunicarlo mediante el formulario de contacto accesible en los sitios web de BeCupon.com desde cada página de los mismos.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        8.3<span style="width:22.07pt; display:inline-block">&#xa0;</span>BeCupon.com&#xa0; excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los servicios, y en particular, aunque no de modo exclusivo, por los daños y perjuicios que puedan deberse al incumplimiento de la ley, la moral y las buenas costumbres generalmente aceptadas o el orden público como consecuencia de la obtención, utilización o acceso a los servicios, la infracción de los derechos de propiedad intelectual e industrial, de los secretos industriales, de compromisos contractuales de cualquier clase, de los derechos al honor, a la intimidad personal y familiar y a la imagen de las personas,
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        de los derechos de propiedad y de toda otra naturaleza pertenecientes a un tercero como consecuencia de la utilización indebida de los servicios, la realización de actos de competencia desleal y publicidad ilícita como consecuencia de la utilización de los servicios, la inadecuación para cualquier clase de propósito y de la defraudación de las expectativas generadas por los servicios, el incumplimiento, retraso en el cumplimiento, cumplimiento defectuoso o terminación por cualquier causa de las obligaciones contraídas por terceros y contratos realizados con terceros a través de o con motivo del acceso a los servicios..
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        9. Servicio al cliente / Support
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        9.1&#xa0; El usuario debe comunicarse con BeCupon vía correo para realizar el reclamo de la no aceptación del cupón por parte de la empresa dueña del cupón.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        9.2- El usuario debe comunicarse con BeCupon vía correo para realizar el reclamo en caso de que después de realizar correctamente el pago con tarjeta/débito/crédito y/o Oxxo u otra forma de pago integrada en la plataforma no funcione a la hora.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        9.3 El usuario puede eliminar su cuenta de BeCupon, solicitando su eliminación desde el siguiente link. https://www.becupon.com/eliminar_cuenta.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        10.<span style="width:22.07pt; display:inline-block">&#xa0;</span>Disposiciones finales
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        10.1<span style="width:16.5pt; display:inline-block">&#xa0;</span>El contrato y sus modificaciones necesitarán la forma escrita. No existen pactos adicionales.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        10.2<span style="width:16.5pt; display:inline-block">&#xa0;</span>Si alguna disposición de las presentes Condiciones Generales fuese o llegase a ser ineficaz, ello no afectaría a la eficacia de las restantes disposiciones. Las partes contratantes se comprometen a sustituir toda disposición ineficaz por una disposición eficaz cuyo contenido regulativo se acerque en la mayor medida posible al sentido y a la finalidad económicos queridos para la disposición ineficaz. La misma regla se aplicará analógicamente en caso de lagunas contractuales.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        10.3<span style="width:16.5pt; display:inline-block">&#xa0;</span>Estas Condiciones Generales quedan sujetas a las leyes de la República Mexicana. Usted acepta presentar a los tribunales de la ciudad de Tijuana, Baja California cualesquiera acciones legales que puedan acontecer relacionadas con el servicio o disputas relacionadas con la interpretación o incumplimiento de las Condiciones Generales.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        10.4<span style="width:16.5pt; display:inline-block">&#xa0;</span>Estas Condiciones Generales y otros términos y condiciones del servicio incorporados en BeCupon.com, constituyen el contrato completo entre usted y BeCupon y regulan el uso del servicio.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.<span style="width:22.07pt; display:inline-block">&#xa0;</span>Cómo adquirir y usar un BeCupon. 
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.1 El usuario puede comprar con tarjeta de débito / crédito. Una vez el pago se realiza de forma exitosa el usuario se hace propietario de un BeCupon y puede canjear los cupones del BeCupon en las sucursales recomendadas por el app en la pantalla de ver el cupón. En la sucursal el QR del cupón es escaneado por el encargado de la sucursal y una vez usado es disminuido ese uso del cupón del sistema.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.2- El usuario puede comprar con Oxxo, el app genera un código de barra para que el usuario realice el pago en Oxxo.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.3- El usuario puede usar todos los cupones que tiene el BeCupon siempre y cuando el BeCupon esté vigente y se cumpla con las condiciones individuales del cupón.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.4 El usuario para hacer válido un cupón de BeCupon, debe ir a la sucursal de la empresa dueña del cupón según se establezca.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.5 Si el usuario no paga con tarjeta de débito/crédito , con Oxxo o con algún otro método de pago no podrá tener acceder a usar los cupones de ese BeCupon.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.6 El cupón solo puede ser usado por el dueño del BeCupon y su NO puede usarse mostrando una foto o un Screenshot del código QR, debe mostrar dentro de la app de BeCupon.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.7- El usuario registrado puede comprar y canjear la cantidad de BeCupon y Cupones que pueda respectivamente tomando en cuenta los límites de la plataforma BeCupon.com. Teniendo en cuenta las restricciones que aplica para el cupón y para el BeCupon.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.8- La empresa BeCupon no se hace responsable si alguna persona que no es el dueño del BeCupon se adueña de su teléfono y usa los cupones sin autorización del dueño del BeCupon.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.9- La empresa BeCupon no se hace responsable si alguna persona que no es el dueño de la cuenta de un usuario la obtiene y hace mal uso de ella.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        11.10- La empresa BeCupon no se hace responsable si alguna persona malintencionada clona la tarjeta del usuario al momento de la compra y/o roba sus datos&#xa0;de&#xa0;su&#xa0;cuenta.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        12.<span style="width:22.07pt; display:inline-block">&#xa0;</span>PRECIO Y PAGO
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        12.1.<span style="width:13.72pt; display:inline-block">&#xa0;</span>I.V.A. y entrega: Los precios serán los indicados en el Sitio Web y las aplicaciones móviles iOS y Android. Estos precios incluyen el Impuesto al Valor Agregado (I.V.A.), pero excluyen los gastos de entrega o transportación, que se añadirán al importe total adeudado, en su caso. La entrega de los BeCupons comprados por los clientes se entregan digitalmente en la aplicación, el usuario que compra el BeCupon tiene el derecho de usar los cupones de dicho BeCupon de acuerdo a los Términos y Condiciones del BeCupon y de cada cupón que lo conforma.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        &#xa0;
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        12.2.<span style="width:13.72pt; display:inline-block">&#xa0;</span>Pedidos Rechazados: Debido a las prácticas bancarias habituales, una vez que hayas realizado un pedido mediante tarjeta de crédito o débito y el pago haya sido autorizado, el adquirente de la operación retendrá el importe total de tu pedido a partir del medio de pago que te hubiera expedido tu emisor, sea que se trate de una tarjeta de crédito o una de débito.
                    </p>
                    <p style="margin-top:0pt; margin-bottom:8pt">
                        Si tu pedido es rechazado posteriormente por el prestador de servicio o un cliente o cancelado por cualquier otra razón, deberás gestionar la devolución de los recursos ante el emisor de la tarjeta con la que hubieras realizado el pago, quien determinará por sí sobre la procedencia de tal devolución, por lo que nos liberas de toda responsabilidad.
                    </p>
	


                </main>
            </div>
        </body>
        <site-footer-details :show-subscribe="true" />
        <copyright />
    </div>
</template>
<style>
    @import 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css';

.input,select {
  margin-left: 10px;
}
</style>