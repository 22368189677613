<template>
  <div class="container mt-4">
    <div class="row pl-2 pr-2 justify-content">
      <div class="col-md-6 col-lg-6 display-contents">
        <stripe-checkout
          ref="checkoutRef"
          mode="payment"
          :pk="publishableKey"
          :lineItems="lineItems"
          :success-url="successURL"
          :cancel-url="cancelURL"
          @loading="(v) => (loading = v)"
        />
        <a
          href="#"
          :class="{ 'color-on-top': top, 'color-primary': top }"
          class="w-100 d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
          @click="goToPay"
        >
          <div class="w-100">
            <div class="text-center">
              <span
                class="fa fa-cart-shoppin icon-size-md mr-3 inline-block"
              ></span>
              <span class="m-2 inline-block" style="font-size: 30px"
                >Comprar BeCupon</span
              >
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
const YOUR_DOMAIN = "http://localhost:8080";
export default {
  data: () => {
    return {
      publishableKey:
        "pk_test_51IfyyWEvbR6sPTImVwEhPAvBKCmRXyGlc72J8gLWekR1w7RiQNlGefUdi8Wiyp0MHx0Er9cv4IBB1YtSp8XwxjUd007lS3R9NQ",
      loading: false,
      lineItems: [
        {
          price: "price_1MYlcoEvbR6sPTImrLdbhr5j",
          quantity: 1,
        },
      ],
      successURL: YOUR_DOMAIN + "/success",
      cancelURL: YOUR_DOMAIN + "/error",
    };
  },
  methods: {
    goToPay() {
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
  name: "BuyButtom",
  components: {
    StripeCheckout,
  },
  props: {
    top: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.display-contents {
  display: contents;
}
.color-on-top {
  background-color: white;
}
.justify-content {
  justify-content: center;
}
.text-white {
  color: #ffffff;
  display: inline-block;
}
.inline-block {
  display: inline-block;
}
</style>
