import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/assets/scss/vuetify/overrides.scss";

Vue.use(Vuetify);

const theme = {
  primary: "#790058",
  info: "#1e88e5",
  success: "#21c1d6",
  active_state: "#b84799",
  no_active_state: "#fdbdeb",
  accent: "#ff0055",
  default: "#563dea",
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
