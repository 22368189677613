<template>
  <div>
    <nav-bar />
    <success-info />

    <div class="d-flex justify-content-center">
      <div class="col-md-6">
        <h4 class="mb-3 text-center">
          <span>Becupon:</span>
        </h4>
        <ul class="list-group mb-3" v-if="this.product">
          <li class="list-group-item d-flex justify-content-center lh-sm">
            <div>
              <div class="card border-0 single-promo-card p-2 mt-2 shadow">
                <div class="card-content">
                  <img
                    class="w-100 border-radius"
                    v-bind:src="this.product.images[0].url"
                  />
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 class="my-0">Nombre:</h6>
            </div>
            <span class="text-body-secondary">{{ this.product.title }}</span>
          </li>
          <li
            class="list-group-item d-flex flex-row justify-content-between lh-sm"
          >
            <div>
              <h6 class="my-0">Subtítulo:</h6>
            </div>
            <span class="text-body-secondary">{{ this.product.subTitle }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 class="my-0">Términos y condiciones del BeCupon:</h6>
              <small class="text-body-secondary">{{
                this.becupon.description
              }}</small>
            </div>
          </li>

          <li
            class="list-group-item d-flex flex-column justify-content-between"
          >
            <div class="d-flex flex-row justify-content-between">
              <span> Fecha y hora </span>

              <span>{{ this.fecha }}</span>
            </div>

            <div class="d-flex flex-row justify-content-between">
              <span> Subtotal </span>

              <span
                >${{
                  calculateSubtotal().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                MXN</span
              >
            </div>

            <div class="d-flex flex-row justify-content-between">
              <span> Cuota de uso de plataforma </span>

              <span
                >${{
                  parseInt(this.fee)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                MXN</span
              >
            </div>

            <div class="d-flex flex-row justify-content-between">
              <span> IVA </span>

              <span
                >${{
                  (
                    this.amountInCents / 100 -
                    calculateSubtotal() -
                    parseFloat(this.fee)
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                MXN</span
              >
            </div>

            <div class="d-flex flex-row justify-content-between">
              <h5 class="text-success">Total:</h5>

              <h5>
                <strong class="text-success">
                  ${{
                    (this.amountInCents / 100)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                  MXN
                </strong>
              </h5>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <site-footer-details :show-subscribe="true" />
    <copyright />
  </div>
</template>
<script>
import NavBar from "../views/commons/NavBar";
import SiteFooterDetails from "../components/becuponDetails/SiteFooterDetails";
import Copyright from "../views/commons/Copyright";
import SuccessInfo from "../components/payment/SuccessInfo.vue";
import gql from "graphql-tag";

const PRUEBA = gql`
  query CouponList($coupons: ID!) {
    couponList(coupons: $coupons) {
      results {
        id
        title
        subTitle
        description
        images {
          id
          url
        }
        smallDescription
        valuedAt
        totalUses
        globalUses
        available
        expirationDate
        initialDate
        amountActives
        coupons {
          id
          title
          subTitle
          description
          initialDate
          expirationDate
          price
          value
          amountActives
          couponAmountActive
          categories {
            id
            name
          }
          cities {
            id
            name
          }
          companies {
            id
            name
          }
          images {
            id
            url
          }
          companyImage
        }
      }
      totalCount
    }
  }
`;

export default {
  name: "SuccessPage",
  components: { NavBar, SiteFooterDetails, Copyright, SuccessInfo },

  data() {
    return {
      amountInCents: null,
      cuponId: null,
      iva: null,
      fee: null,
      userId: null,
      session_id: null,

      product: null,
      becupon: null,

      fecha: null,
    };
  },

  async mounted() {
    await this.$apollo
      .query({
        query: PRUEBA,
        variables: {
          coupons: this.cuponId,
        },
      })
      .then((response) => {
        this.product = response.data.couponList.results[0].coupons;
        this.becupon = response.data.couponList.results[0];
      });

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    const months = [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ];

    this.fecha = `${day}  ${months[month]}  ${year}, ${
      hours <= 12 ? hours : hours - 12
    }:${minutes < 10 ? `0${minutes}` : minutes} ${hours < 12 ? "AM" : "PM"}`;
  },

  async created() {
    this.amountInCents = this.$route.query.amountInCents;
    this.cuponId = this.$route.query.cuponId;
    this.iva = this.$route.query.iva;
    this.fee = this.$route.query.fee;
    this.userId = this.$route.query.userId;
    this.session_id = this.$route.query.session_id;
  },

  methods: {
    calculateSubtotal() {
      let amount = this.amountInCents / 100;

      let iva = 1 + parseFloat(this.iva);

      let subtotal = amount / iva - parseFloat(this.fee);

      return subtotal.toFixed(2);
    },
  },
};
</script>

<style>
.gray-line {
  width: 100px;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}
</style>
